import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthContext"; // Asegúrate de importar el contexto
import Swal from "sweetalert2";

export default function Protected(props) {
  const { isAuthenticated } = useAuth(); // Obtén el estado de autenticación
  const navigate = useNavigate();
  const { Component } = props;

  useEffect(() => {
    if (!isAuthenticated) {
      mostrarAlertaIni();
      navigate("/", { replace: true });
    }
  }, [isAuthenticated, navigate]);

  const mostrarAlertaIni = () => {
    Swal.fire({
      showConfirmButton: false,
      title: "Error!",
      text: "Por favor inicie sesión!",
      timer: 5000,
      icon: "error",
    });
  };

  return isAuthenticated ? <Component /> : null; // Renderiza el componente solo si está autenticado
}
