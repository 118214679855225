import React, { useState, useEffect } from "react";
import "../navbar.scss";
import Logo from "../img/base/111.png";
import axios from "axios";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavbarText,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Input,
  Label,
  Row,
  Card,
  CardBody,
} from "reactstrap";
import * as FaIcons from "react-icons/fa";
import { Outlet, useNavigate, Link } from "react-router-dom";
import SidebarPer from "./SidebarPer";
import Swal from "sweetalert2";
import { useAuth } from "../../utils/AuthContext";
import useIdleTimer from "../../utils/useIdleTimer";

const TIMEOUT_DURATION = 10 * 60 * 1000; // 10 minutos en milisegundos

function NavbarBec(args) {
  ////
  const [modalContra, setModalContra] = useState(false);
  const abrirCerrarModalContra = () => {
    setModalContra(!modalContra);
  };
  ////FECHA
  const [modalFecha, setModalFecha] = useState(false);
  const abrirCerrarModalFecha = () => {
    setModalFecha(!modalFecha);
  };

  ////Mostrar fecha
  const [usuarioSeleccionado, setusuarioSeleccionado] = useState({
    id_fech: "",
    fecha: "",
    fech_lit: "",
  });
  const [fechaError, setFechaError] = useState(false);

  const handleChangefecha = (e) => {
    const { name, value } = e.target;
    setusuarioSeleccionado((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (value === "") {
      setFechaError(true);
    } else {
      setFechaError(false);
    }
  };
  /////Listar datos personal administrativo
  const [dataPer, setDataPer] = useState({});
  const peticionGetContraseña = async () => {
    await axios
      .get(`https://bqef-bo.com/api/P/contraper.php`, {
        params: {
          id: sessionStorage.getItem("iduser"),
        },
      })
      .then((response) => {
        //console.log(response.data);;
        setDataPer(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  ////LOGOUT
  ////sesion cierre
  const { isAuthenticated, logoutSubmit } = useAuth();
  const [remainingTime, setRemainingTime] = useState(TIMEOUT_DURATION);

  // Configurar el temporizador de inactividad
  useIdleTimer(TIMEOUT_DURATION, logoutSubmit, setRemainingTime);

  // Iniciar el temporizador solo si el usuario está autenticado
  useEffect(() => {
    if (isAuthenticated) {
      setRemainingTime(TIMEOUT_DURATION); // Reiniciar el tiempo restante
    } else {
      setRemainingTime(TIMEOUT_DURATION); // Reiniciar el tiempo si el usuario no está autenticado
    }
  }, [isAuthenticated]);

  // Función para formatear el tiempo restante en minutos y segundos
  const formatTime = (time) => {
    const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((time % (1000 * 60)) / 1000);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };
  //
  const navigate = useNavigate();
  // LOGOUT
  const logoutSub = () => {
    // Mostrar alerta de confirmación
    Swal.fire({
      title: "¿Está seguro?",
      text: "¿Desea cerrar sesión?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, cerrar sesión!",
    }).then((result) => {
      if (result.isConfirmed) {
        sessionStorage.clear(); // Limpia la sesión
        logoutSubmit(); // Actualiza el estado de autenticación
        navigate("/"); // Redirige a la página de inicio
      }
    });
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  ////Modificar contraseña Personal Administrativo
  const peticionPutContra = async () => {
    var f = new FormData();
    f.append("contrasena", dataPer.contrasena);
    f.append("contrasena_lit", dataPer.contrasena_lit);
    f.append("usu_modificacion", dataPer.id_per);
    f.append("METHOD", "PUT");
    await axios
      .post(`https://bqef-bo.com/api/P/contraper.php`, f, {
        params: { idb: dataPer.id },
      })
      .then((response) => {
        setDataPer(response);
        peticionGetContraseña();
        mostrarAlertaU();
        abrirCerrarModalContra();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  ////Mostrar fecha
  const [listaF, setListaF] = useState([]);
  const peticionGetFecha = async () => {
    await axios
      .get(`https://bqef-bo.com/api/P/fecIP.php`)
      .then((response) => {
        //console.log(response.data);;
        setusuarioSeleccionado(response.data);
        setListaF(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  ////Modificar Fecha
  async function addFecha(e) {
    e.preventDefault();
    let fd = new FormData();
    fd.append("fecha", usuarioSeleccionado.fecha);
    const res = await axios.post("https://bqef-bo.com/api/P/fecIP.php", fd);
    console.log(res.data);
    mostrarAlertaFec();
    abrirCerrarModalFecha();
    peticionGetFecha();
  }
  ////
  useEffect(() => {
    peticionGetFecha();
    peticionGetContraseña();
  }, []);

  ////Mostrar comunicados
  const [lista, setLista] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  useEffect(() => {
    getComunicado();
  }, []);
  async function getComunicado() {
    const res = await axios.get("https://bqef-bo.com/api/A/comCA.php");
    setLista(res.data);
    // console.log(res.data);
  }

  ////Descargar PDF
  const handleDescargarPdf = async () => {
    var link = document.createElement("a");
    link.setAttribute(
      "href",
      "data:application/pdf;base64," + selectedItem.archivo_com
    );
    link.setAttribute("download", selectedItem.nom_doc);
    link.click();
  };

  ////ALERTAS
  const mostrarAlertaU = () => {
    Swal.fire({
      confirmButtonColor: "#2E8B57",
      title: "Éxito!",
      text: "Contraseña Actualizada.",
      timer: 5000,
      icon: "success",
    });
  };
  const mostrarAlertaFec = () => {
    Swal.fire({
      confirmButtonColor: "#2E8B57",
      title: "Éxito!",
      text: "Fecha Actualizada.",
      timer: 5000,
      icon: "success",
    });
  };
  ////
  const handleSelectItem = (item) => {
    setSelectedItem(item);
  };
  ////Boton Cerrar
  const closeBtn = () => {
    setSelectedItem(null);
  };
  const cloBtn = (
    <Button className="close" onClick={closeBtn}>
      &times;
    </Button>
  );
  ////Modal VER MANUAL PERSONAL
  const [modalVerManual, setModalVerManual] = useState(false);
  const abrirCerrarModalVerManual = () => {
    setModalVerManual(!modalVerManual);
  };
  const toggleM = () => setModalVerManual(!modalVerManual);
  const closeBtn4 = (
    <Button className="close" onClick={() => abrirCerrarModalVerManual()}>
      &times;
    </Button>
  );
  ///pdf ver manual
  const handleDownloadPdf = () => {
    const link = document.createElement("a");
    link.href = require("../../Pages/Admin/documentos/manuales/MANUAL USUARIO PERSONAL.pdf");
    link.download = "MANUAL USUARIO PERSONAL.pdf";
    link.click();
  };
  // Definir un objeto de validación para contraseña
  const validation = {
    contrasena: {
      regex:
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%?&%"*#¡¿])[A-Za-z\d@$!%?&%"*#¡¿]{8,}$/,
      isValid: true,
    },
  };

  const [isValid, setIsValid] = useState({
    contrasena: false,
  });
  ///
  const handleChange = (e) => {
    const { name, value } = e.target;
    setDataPer((prevState) => ({ ...prevState, [name]: value }));

    const isValidValue = validation[name].regex.test(value);
    setIsValid((prevState) => ({ ...prevState, [name]: isValidValue }));
  };
  return (
    <div>
      <Navbar expand="md" {...args}>
        <SidebarPer />
        <NavbarBrand>
          <Link to="/Personal/Personal" className="text-light">
            <img
              src={Logo}
              alt="logo"
              className="me-2"
              style={{
                height: 60,
                width: 120,
              }}
            />
          </Link>
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ms-auto" navbar>
            <NavbarText className="text-light">
              {/* Mostrar el temporizador de inactividad solo si el usuario está autenticado */}
              {isAuthenticated && (
                <div>Cierre de sesión en: {formatTime(remainingTime)}</div>
              )}
            </NavbarText>
            <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
            {/* //REVISAR */}
            <NavbarText className="text-light">
              {dataPer.nombre}
              {"   "}
              {dataPer.apellido}
            </NavbarText>
            <UncontrolledDropdown nav direction="down">
              <DropdownToggle nav caret className="text-light">
                MENU
              </DropdownToggle>
              <DropdownMenu className="drop-menu">
                <DropdownItem onClick={() => abrirCerrarModalVerManual()}>
                  Instrucciones
                </DropdownItem>
                {lista.map((item) => (
                  <DropdownItem
                    key={item.id_com}
                    onClick={() => handleSelectItem(item)}>
                    Postulacion
                  </DropdownItem>
                ))}
                <DropdownItem onClick={() => abrirCerrarModalFecha()}>
                  Fecha Entrega
                </DropdownItem>
                <DropdownItem href="http://www.bqef.org/" target="_blank">
                  Sobre Nosotros...
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={() => abrirCerrarModalContra()}>
                  Cambiar Contraseña
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={logoutSub}>Salir</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </Navbar>

      {/* MODAL CONTRASEÑA */}
      <Modal isOpen={modalContra}>
        <ModalHeader className="header_mo">Editar Contraseña</ModalHeader>
        <ModalBody>
          <div className="form-group">
            <Label>Clave Actual: </Label>
            <br />
            <Input
              disabled
              type="text"
              className="form-control"
              name="contrasena_lit"
              onChange={handleChange}
              value={dataPer.contrasena_lit}
            />
            <br />
            <Label>Nueva Clave: </Label>
            <br />
            <Input
              type="text"
              className="form-control"
              name="contrasena"
              onChange={handleChange}
              // value={dataPer.contrasena}
            />
            {!isValid.contrasena && (
              <span style={{ color: "red" }}>
                La contraseña debe tener al menos 8 caracteres, incluyendo
                mayúsculas, minúsculas, números y caracteres especiales
              </span>
            )}
            <br />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="success"
            size="lg"
            onClick={() => peticionPutContra()}
            disabled={!isValid.contrasena}>
            Guardar
          </Button>
          {"   "}
          <Button
            color="danger"
            size="lg"
            onClick={() => abrirCerrarModalContra()}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>

      {/* MODAL FECHA */}
      <Modal isOpen={modalFecha}>
        <ModalHeader className="header_mo">
          Establecer Fecha Entrega
        </ModalHeader>
        <ModalBody>
          <div className="form-group">
            <Label>Fecha Establecida: </Label>
            {listaF.map((item) => (
              <h2 className="text-center" key={item.id_fech}>
                "{item.fech_lit}"
              </h2>
            ))}
            <Label>Nueva Fecha: </Label>
            <br />
            <Input
              type="date"
              className="form-control"
              name="fecha"
              value={usuarioSeleccionado.fecha}
              onChange={handleChangefecha}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="success"
            size="lg"
            onClick={addFecha}
            disabled={fechaError || usuarioSeleccionado.fecha === ""}>
            Guardar
          </Button>
          {fechaError && (
            <span style={{ color: "red" }}>
              Please select a valid date and time before saving
            </span>
          )}
          {"   "}
          <Button
            color="danger"
            size="lg"
            onClick={() => abrirCerrarModalFecha()}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>

      {/* Modal VER MANUAL PERSONAL*/}
      <Modal isOpen={modalVerManual} size="xl">
        <ModalHeader className="header_mo" toggle={toggleM} close={closeBtn4}>
          Manual Usuario Personal Administrativo
        </ModalHeader>
        <ModalBody>
          <Card>
            <CardBody className="p-0">
              <Row className="justify-content-center">
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    height: "500px",
                  }}>
                  <object
                    data={require("../../Pages/Admin/documentos/manuales/MANUAL USUARIO PERSONAL.pdf")}
                    type="application/pdf"
                    width="100%"
                    height="100%">
                    <p>
                      Este navegador no soporta PDFs. Por favor,
                      <a
                        href={require("../../Pages/Admin/documentos/manuales/MANUAL USUARIO PERSONAL.pdf")}>
                        {" "}
                        descarga el PDF
                      </a>{" "}
                      para verlo.
                    </p>
                  </object>
                </div>
              </Row>
            </CardBody>
          </Card>
        </ModalBody>
        <ModalFooter>
          <Button
            style={{ float: "right" }}
            color="success"
            size="lg"
            onClick={handleDownloadPdf}>
            <FaIcons.FaDownload />
            Download
          </Button>
        </ModalFooter>
      </Modal>

      {/* Modal VER CONVOCATORIA */}
      <Modal isOpen={selectedItem !== null} size="xl">
        {selectedItem && (
          <>
            <ModalHeader className="header_m" close={cloBtn}>
              {selectedItem.nom_doc}
            </ModalHeader>
            <ModalBody>
              <Card>
                <CardBody className="p-0">
                  <Row className="justify-content-center">
                    <object
                      data={
                        "data:application/pdf;base64," +
                        selectedItem.archivo_com
                      }
                      type="application/pdf"
                      alt="archivo_per"
                      width="400"
                      height="600">
                      <p>
                        Tu navegador no puede mostrar este archivo PDF. Puedes
                        descargarlo
                        <a
                          href={
                            "data:application/pdf;base64," +
                            selectedItem.archivo_com
                          }
                          download>
                          aquí
                        </a>
                        .
                      </p>
                    </object>
                  </Row>
                </CardBody>
              </Card>
            </ModalBody>
            <ModalFooter>
              <Button
                style={{ float: "right" }}
                color="success"
                size="lg"
                onClick={handleDescargarPdf}>
                <FaIcons.FaDownload />
                Download
              </Button>
            </ModalFooter>
          </>
        )}
      </Modal>
      <Outlet />
    </div>
  );
}

export default NavbarBec;
