import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormGroup,
  Form,
} from "reactstrap";
import { Table, Button, Label, Input, Row } from "reactstrap";
import * as FaIcons from "react-icons/fa";
import axios from "axios";
import "../Bec/bec.scss";
import Swal from "sweetalert2";

function Informe2() {
  const [usuarioSeleccionado, setUsuarioSeleccionado] = useState({
    id_doc: "",
    nom_doc: "",
    nom_usu: "",
    archivo_per: null,
    f_cargado: "",
  });
  // Definir un objeto de validación con patrones de expresiones regulares y estados de validación iniciales
  const validation = {
    id_doc: { regex: /^[a-zA-Z\s]{1,20}$/, isValid: true },
    nom_doc: { regex: /^[a-zA-Z\s]{1,20}$/, isValid: true },
    nom_usu: { regex: /^[a-zA-Z\s]{1,20}$/, isValid: true },
    f_cargado: { isValid: false },
    archivo_per: { isValid: false },
  };

  const [isValid, setIsValid] = useState({
    id_doc: true,
    nom_doc: true,
    nom_usu: true,
    f_cargado: true,
    archivo_per: false,
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "f_cargado") {
      // Manejar cambio de fecha y hora
      setUsuarioSeleccionado((prevState) => ({ ...prevState, [name]: value }));
      const isValidValue = value !== "";
      setIsValid((prevState) => ({ ...prevState, [name]: isValidValue }));
    } else {
      // Manejar cambio de texto
      setUsuarioSeleccionado((prevState) => ({ ...prevState, [name]: value }));
      if (validation[name] && validation[name].regex) {
        const isValidValue = validation[name].regex.test(value);
        setIsValid((prevState) => ({ ...prevState, [name]: isValidValue }));
      }
    }
  };
  //modal insertar
  const [modalInsertar, setModalInsertar] = useState(false);
  const abrirCerrarModalInsertar = () => {
    setModalInsertar(!modalInsertar);
  };
  //modal eliminar
  const [modalEliminar, setModalEliminar] = useState(false);
  const abrirCerrarModalEliminar = () => {
    setModalEliminar(!modalEliminar);
  };
  //modal ver
  const [modalVer, setModalVer] = useState(false);
  const abrirCerrarModalVer = () => {
    setModalVer(!modalVer);
  };
  ////Datos usuario Administrador
  const [dataAdm, setDataAdm] = useState({});
  const peticionGetAdm = async () => {
    await axios
      .get(`https://bqef-bo.com/api/A/contraadm.php`, {
        params: {
          id: sessionStorage.getItem("iduser"),
        },
      })
      .then((response) => {
        //console.log(response.data);;
        setDataAdm(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  /////Listado informes
  const baseUrl = "https://bqef-bo.com/api/A/cruCInf.php";
  const [data, setData] = useState([]);
  async function getImagenes() {
    const res = await axios.get(baseUrl);
    setData(res.data);
    // console.log(res.data);
    setTablaUsuarios(res.data);
  }
  /////Cargar informes
  const [informe, setInforme] = useState(null);
  async function cargarinfo(e) {
    e.preventDefault();
    let fd = new FormData();
    fd.append("archivo_per", informe);
    fd.append("usu_creacion", dataAdm.id_adm);
    fd.append("nom_usu", usuarioSeleccionado.nom_usu);
    fd.append("nom_doc", usuarioSeleccionado.nom_doc);
    fd.append("f_cargado", usuarioSeleccionado.f_cargado);
    const res = await axios.post(baseUrl, fd);
    console.log(res.data);
    abrirCerrarModalInsertar();
    mostrarAlertaN();
    getImagenes();
  }
  /////Eliminar informe
  async function deleteImagen(id_doc) {
    const res = await axios.delete(`${baseUrl}?id_doc=${id_doc}`);
    abrirCerrarModalEliminar();
    getImagenes();
    mostrarAlertaD();
    console.log(res.data);
  }

  const seleccionarUsuario = (Usuario, caso) => {
    setUsuarioSeleccionado(Usuario);

    caso === "Visualizar" ? abrirCerrarModalVer() : abrirCerrarModalEliminar();
  };

  ////Descargar informes en PDF
  const handleDescargarPdf = async () => {
    var link = document.createElement("a");
    // Se agregan los prefijos de href para indicar que el contenido que sigue está en formato PDF y
    // está codificado en Base64.
    link.setAttribute(
      "href",
      "data:application/pdf;base64," + usuarioSeleccionado.archivo_per
    );
    link.setAttribute("download", usuarioSeleccionado.nom_doc);
    link.click();
  };
  const closeBtn = (
    <Button className="close" onClick={() => abrirCerrarModalVer()}>
      &times;
    </Button>
  );

  useEffect(() => {
    getImagenes();
    peticionGetAdm();
  }, []);
  ////BARRA BUSQUEDA
  const [busqueda, setBusqueda] = useState("");
  const [tablaUsuarios, setTablaUsuarios] = useState([]);

  const handleChangeB = (e) => {
    setBusqueda(e.target.value);
    filtrar(e.target.value);
  };

  const filtrar = (terminoBusqueda) => {
    var resultadosBusqueda = tablaUsuarios.filter((elemento) => {
      if (
        elemento.nom_doc
          .toString()
          .toLowerCase()
          .includes(terminoBusqueda.toLowerCase()) ||
        elemento.nom_usu
          .toString()
          .toLowerCase()
          .includes(terminoBusqueda.toLowerCase())
      ) {
        return elemento;
      }
    });
    setData(resultadosBusqueda);
  };
  ////ALERTAS
  const mostrarAlertaN = () => {
    Swal.fire({
      confirmButtonColor: "#2E8B57",
      title: "Éxito!",
      text: "Documento cargado",
      timer: 5000,
      icon: "success",
    });
  };
  const mostrarAlertaD = () => {
    Swal.fire({
      confirmButtonColor: "#2E8B57",
      title: "Eliminado!",
      text: "Documento Eliminado.",
      timer: 5000,
      icon: "success",
    });
  };
  //////Filtrar columnas
  const [sortField, setSortField] = useState(null);
  const [order, setOrder] = useState("asc");

  const handleSortingChange = (accessor) => {
    const sortOrder =
      accessor === sortField && order === "asc" ? "desc" : "asc";
    setSortField(accessor);
    setOrder(sortOrder);
  };

  const sortedData = data.sort((a, b) => {
    if (a[sortField] < b[sortField]) {
      return order === "asc" ? -1 : 1;
    }
    if (a[sortField] > b[sortField]) {
      return order === "asc" ? 1 : -1;
    }
    return 0;
  });
  return (
    <div id="main_content">
      <div className="tra">
        <div className="tra title-form">
          <h2 className>Listado Informes</h2>
        </div>
        <div className="frm">
          <Button
            color="success"
            size="lg"
            onClick={() => abrirCerrarModalInsertar()}>
            <FaIcons.FaPlus /> Añadir
          </Button>
        </div>
        <div className="frm containerInput">
          <Input
            className="form-control inputBuscar"
            size="lg"
            value={busqueda}
            placeholder="Búsqueda por Nombre documento o Nombre usuario"
            onChange={handleChangeB}
          />
          <Button className="btn btn-success" size="lg">
            <FaIcons.FaSearch /> Buscar
          </Button>
        </div>

        <Table className="frm" responsive="sm" id="tabl">
          <thead id="stahead">
            <tr className="text-center">
              <th>N°</th>
              <th>
                <span onClick={() => handleSortingChange("nom_doc")}>
                  Nombre documento
                  {sortField === "nom_doc" && order === "asc" ? (
                    <FaIcons.FaSortUp />
                  ) : sortField === "nom_doc" && order === "desc" ? (
                    <FaIcons.FaSortDown />
                  ) : (
                    <FaIcons.FaSort />
                  )}
                </span>
              </th>
              <th>
                <span onClick={() => handleSortingChange("nom_usu")}>
                  Usuario
                  {sortField === "nom_usu" && order === "asc" ? (
                    <FaIcons.FaSortUp />
                  ) : sortField === "nom_usu" && order === "desc" ? (
                    <FaIcons.FaSortDown />
                  ) : (
                    <FaIcons.FaSort />
                  )}
                </span>
              </th>
              <th>
                <span onClick={() => handleSortingChange("f_cargado")}>
                  Fecha Cargado
                  {sortField === "f_cargado" && order === "asc" ? (
                    <FaIcons.FaSortUp />
                  ) : sortField === "f_cargado" && order === "desc" ? (
                    <FaIcons.FaSortDown />
                  ) : (
                    <FaIcons.FaSort />
                  )}
                </span>
              </th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {sortedData.map((item, n2) => (
              <tr className="text-center" key={item.id_doc}>
                <td>{n2 + 1}</td>
                <td>{item.nom_doc}</td>
                <td>{item.nom_usu}</td>
                <td>{item.f_cargado}</td>
                <td>
                  <Button
                    color="warning"
                    onClick={() => seleccionarUsuario(item, "Visualizar")}>
                    <FaIcons.FaRegEye />
                    &nbsp;&nbsp;Visualizar
                  </Button>
                  &nbsp;&nbsp;&nbsp;
                  <Button
                    color="danger"
                    onClick={() => seleccionarUsuario(item, "Eliminar")}>
                    Eliminar
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {/* Modal añadir informe */}
        <Modal isOpen={modalInsertar}>
          <ModalHeader className="header_mo">Cargar documento</ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label>Nombre documento: </Label>
                <br />
                <Input
                  type="text"
                  className="form-control"
                  name="nom_doc"
                  value={usuarioSeleccionado.nom_doc}
                  onChange={handleChange}
                />
                {!isValid.nom_doc && (
                  <span style={{ color: "red" }}>
                    El texto debe tener como máximo 20 letras y espacios
                  </span>
                )}
              </FormGroup>
              <FormGroup>
                <Label>Usuario: </Label>
                <br />
                <Input
                  type="text"
                  className="form-control"
                  name="nom_usu"
                  value={usuarioSeleccionado.nom_usu}
                  onChange={handleChange}
                />
                {!isValid.nom_usu && (
                  <span style={{ color: "red" }}>
                    El texto debe tener como máximo 20 letras y espacios
                  </span>
                )}
              </FormGroup>
              <FormGroup>
                <Label>Informe personal: </Label>
                <Input
                  type="file"
                  className="form-control"
                  accept="archivo_per/*"
                  onChange={(e) => setInforme(e.target.files[0])}
                  multiple
                />
                <br />
                <Label>Fecha cargado: </Label>
                <Input
                  type="date"
                  className="form-control"
                  name="f_cargado"
                  onChange={handleChange}
                />
                {!isValid.f_cargado && (
                  <span style={{ color: "red" }}>
                    Debe seleccionar una fecha y hora válida
                  </span>
                )}
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="success"
              size="lg"
              onClick={(e) => cargarinfo(e)}
              disabled={
                !isValid.nom_doc || !isValid.nom_usu || !isValid.f_cargado
              }>
              Guardar
            </Button>
            <Button
              color="danger"
              size="lg"
              onClick={() => abrirCerrarModalInsertar()}>
              Cancelar
            </Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={modalEliminar}>
          <ModalHeader className="header_mo">
            Eliminar informe becario
          </ModalHeader>
          <ModalBody>
            ¿Estás seguro que deseas eliminar el informe{" "}
            {usuarioSeleccionado && usuarioSeleccionado.nom_doc}?
          </ModalBody>
          <ModalFooter>
            <Button
              color="success"
              size="lg"
              onClick={() => deleteImagen(usuarioSeleccionado.id_doc)}>
              Sí
            </Button>
            <Button
              color="danger"
              size="lg"
              onClick={() => abrirCerrarModalEliminar()}>
              No
            </Button>
          </ModalFooter>
        </Modal>

        {/* Modal VER */}
        <Modal isOpen={modalVer} size="lg" className="modal-responsive">
          <ModalHeader close={closeBtn} className="header_mo">
            {usuarioSeleccionado && usuarioSeleccionado.nom_doc}
          </ModalHeader>
          <ModalBody className="p-0">
            <Card>
              <CardBody className="p-0">
                <Row className="justify-content-center">
                  <object
                    data={
                      "data:application/pdf;base64," +
                      usuarioSeleccionado.archivo_per
                    }
                    type="application/pdf"
                    alt="archivo_per"
                    style={{ width: "100%", height: "80vh" }} // Ajuste para dispositivos móviles
                  >
                    <p>
                      Tu navegador no puede mostrar este archivo PDF. Puedes
                      descargarlo
                      <a
                        href={
                          "data:application/pdf;base64," +
                          usuarioSeleccionado.archivo_per
                        }
                        download>
                        aquí
                      </a>
                      .
                    </p>
                  </object>
                </Row>
              </CardBody>
            </Card>
          </ModalBody>
          <ModalFooter>
            <Button
              style={{ float: "right" }}
              color="success"
              size="lg"
              onClick={handleDescargarPdf}>
              <FaIcons.FaDownload />
              Download
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
}

export default Informe2;
