import { useEffect } from "react";

const useIdleTimer = (timeoutDuration, logoutSubmit, setRemainingTime) => {
  useEffect(() => {
    let timeoutId;
    let remainingTime = timeoutDuration;

    const handleActivity = () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      // Reinicia el temporizador
      remainingTime = timeoutDuration;
      setRemainingTime(remainingTime);
      timeoutId = setTimeout(() => {
        logoutSubmit(); // Llama a logoutSubmit después de que se agote el tiempo
      }, remainingTime);
    };

    // Escucha eventos de actividad
    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("keypress", handleActivity);
    window.addEventListener("scroll", handleActivity);
    window.addEventListener("click", handleActivity);

    // Configura el temporizador inicialmente
    timeoutId = setTimeout(() => {
      logoutSubmit();
    }, remainingTime);

    // Actualiza el temporizador cada segundo
    const intervalId = setInterval(() => {
      remainingTime -= 1000; // Disminuye 1 segundo
      setRemainingTime(remainingTime);
      if (remainingTime <= 0) {
        clearInterval(intervalId);
      }
    }, 1000);

    // Limpieza de efectos
    return () => {
      clearTimeout(timeoutId);
      clearInterval(intervalId);
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("keypress", handleActivity);
      window.removeEventListener("scroll", handleActivity);
      window.removeEventListener("click", handleActivity);
    };
  }, [logoutSubmit, timeoutDuration, setRemainingTime]);
};

export default useIdleTimer;
