import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/scss/bootstrap.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import App from "./App";
import { AuthProvider } from "./utils/AuthContext"; // Asegúrate de que la ruta sea correcta
import * as FaIcons from "react-icons/fa";

const root = ReactDOM.createRoot(document.getElementById("root"));

const ThemeButton = () => {
  const [theme, setTheme] = useState("light");

  const handleChangeTheme = () => {
    setTheme(theme === "light" ? "dark" : "light");
    document.body.classList.toggle("dark-mode");
  };

  return (
    <button className="theme-button" onClick={handleChangeTheme}>
      <FaIcons.FaMoon />
    </button>
  );
};

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        {" "}
        {/* Agrega el AuthProvider aquí */}
        <App />
      </AuthProvider>
    </BrowserRouter>
    <ThemeButton />
  </React.StrictMode>
);
