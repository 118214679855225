import React, { createContext, useContext, useState } from "react";

// Crear el contexto
const AuthContext = createContext();

// Proveedor del contexto
export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    // Recuperar el estado de autenticación desde sessionStorage
    return sessionStorage.getItem("isAuthenticated") === "true";
  });

  const login = () => {
    setIsAuthenticated(true);
    sessionStorage.setItem("isAuthenticated", "true"); // Guardar en sessionStorage
  };

  const logoutSubmit = () => {
    setIsAuthenticated(false);
    sessionStorage.removeItem("isAuthenticated"); // Eliminar del sessionStorage
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logoutSubmit }}>
      {children}
    </AuthContext.Provider>
  );
};

// Hook para usar el contexto
export const useAuth = () => {
  return useContext(AuthContext);
};
