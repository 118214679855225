import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  Button,
} from "reactstrap";
import * as FaIcons from "react-icons/fa";
import axios from "axios";
import "../Bec/bec.scss";
import Swal from "sweetalert2";
import jsPDF from "jspdf";
import logos from "../../components/img/base/111.png";

function Evalua() {
  const baseUrl = `https://bqef-bo.com/api/A/dashNotBec.php`;
  const [modalEditar, setModalEditar] = useState(false);
  const [usuarioSeleccionado, setusuarioSeleccionado] = useState({
    id: "",
    nombre: "",
    apellido: "",
    email: "",
    contrasena: "",
    celular: "",
    nota_eva: "",
    promedio_semestre: "",
  });
  ////1
  const [fram1, setFram1] = useState("");
  const [isValid, setIsValid] = useState({
    p1: false,
    p2: false,
    p3: false,
    p4: false,
  });

  const cambioRadioFram1 = (e) => {
    const { value } = e.target;
    setFram1(value);
    const isValidValue = value === "25" || value === "13" || value === "1";
    setIsValid((prevState) => ({ ...prevState, p1: isValidValue }));
  };
  ///2
  const [fram2, setFram2] = useState();

  const cambioRadioFram2 = (e) => {
    const { value } = e.target;
    setFram2(value);
    const isValidValue = value === "25" || value === "13" || value === "1";
    setIsValid((prevState) => ({ ...prevState, p2: isValidValue }));
  };
  ///3
  const [fram3, setFram3] = useState();

  const cambioRadioFram3 = (e) => {
    const { value } = e.target;
    setFram3(value);
    const isValidValue = value === "25" || value === "13" || value === "1";
    setIsValid((prevState) => ({ ...prevState, p3: isValidValue }));
  };
  ///4
  const [fram4, setFram4] = useState();

  const cambioRadioFram4 = (e) => {
    const { value } = e.target;
    setFram4(value);
    const isValidValue = value === "25" || value === "13" || value === "1";
    setIsValid((prevState) => ({ ...prevState, p4: isValidValue }));
  };
  ////
  const abrirCerrarModalEditar = () => {
    setModalEditar(!modalEditar);
  };
  ////Listar datos becarios
  const [dataBE, setDataBE] = useState([]);

  const peticionGet = async () => {
    await axios
      .get(baseUrl)
      .then((response) => {
        //console.log(response.data);;
        setDataBE(response.data);
        setTablaUsuarios(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  ////Listar Notas Gestión Becarios
  const [dataNG, setDataNG] = useState([]);

  useEffect(() => {
    // Cargar datos desde un API o un archivo JSON
    fetch("https://bqef-bo.com/api/A/dashNotBec.php")
      .then((res) => res.json())
      .then((data) => {
        setDataNG(data); // Establecer los datos en el estado
        console.log(data); // Mostrar los datos por consola
      })
      .catch((error) => {
        console.error("Error al cargar los datos:", error); // Manejo de errores
      });
  }, []);
  ////Evaluacion becarios
  const peticionPut = async () => {
    var f = new FormData();
    f.append("p1", fram1);
    f.append("p2", fram2);
    f.append("p3", fram3);
    f.append("p4", fram4);
    f.append("METHOD", "POST");
    await axios
      .post("https://bqef-bo.com/api/P/evaB.php", f, {
        params: { id: usuarioSeleccionado.id_bec },
      })
      .then((response) => {
        setDataBE(dataBE.concat(response.data));
        //console.log(response.data);;
        peticionGet();
        mostrarAlertaEv();
        abrirCerrarModalEditar();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  /////
  const seleccionarUsuario = (Usuario) => {
    setusuarioSeleccionado(Usuario);
    abrirCerrarModalEditar();
  };
  useEffect(() => {
    peticionGet();
  }, []);
  ////BARRA BUSQUEDA
  const [busqueda, setBusqueda] = useState("");
  const [tablaUsuarios, setTablaUsuarios] = useState([]);
  // const [usuarios, setUsuarios] = useState([]);

  const handleChangeB = (e) => {
    setBusqueda(e.target.value);
    filtrar(e.target.value);
  };

  const filtrar = (terminoBusqueda) => {
    var resultadosBusqueda = tablaUsuarios.filter(
      (elemento) =>
        elemento.nombre
          .toString()
          .toLowerCase()
          .includes(terminoBusqueda.toLowerCase()) ||
        elemento.apellido
          .toString()
          .toLowerCase()
          .includes(terminoBusqueda.toLowerCase()) ||
        elemento.email
          .toString()
          .toLowerCase()
          .includes(terminoBusqueda.toLowerCase()) ||
        elemento.celular
          .toString()
          .toLowerCase()
          .includes(terminoBusqueda.toLowerCase()) ||
        elemento.nota_eva
          .toString()
          .toLowerCase()
          .includes(terminoBusqueda.toLowerCase())
    );
    setDataBE(resultadosBusqueda);
  };
  ////ALERTAS
  const mostrarAlertaEv = () => {
    Swal.fire({
      confirmButtonColor: "#2E8B57",
      title: "Éxito!",
      text: "Registro Guardado.",
      timer: 5000,
      icon: "success",
    });
  };
  // Filtrar columnas
  const [sortField, setSortField] = useState(null);
  const [order, setOrder] = useState("asc");

  const handleSortingChange = (accessor) => {
    const sortOrder =
      accessor === sortField && order === "asc" ? "desc" : "asc";
    setSortField(accessor);
    setOrder(sortOrder);
  };

  const sortedData = dataBE.sort((a, b) => {
    if (!sortField) return 0; // Si no hay campo para ordenar, no hacemos nada
    if (a[sortField] < b[sortField]) {
      return order === "asc" ? -1 : 1;
    }
    if (a[sortField] > b[sortField]) {
      return order === "asc" ? 1 : -1;
    }
    return 0;
  });
  ///GENERAR PDF INFORME
  const generatePDF = () => {
    // Crear un nuevo documento PDF en orientación vertical
    const doc = new jsPDF();

    // Agregar logo al documento PDF
    doc.addImage(logos, "PNG", 10, 10, 20, 10);

    // Establecer la fuente en negrita
    doc.setFont("helvetica", "bold");

    // Agregar título "FONDO DE EDUCACIÓN" al documento PDF
    doc.setFontSize(12);
    const title = "FONDO DE EDUCACION DE CUAQUEROS BOLIVIANOS";
    const titleWidth = doc.getTextWidth(title);
    const x = (doc.internal.pageSize.width - titleWidth) / 2; // Calcular posición X para centrar
    const y = 20; // Posición Y del título

    // Escribir el título
    doc.text(title, x, y);

    // Dibujar la línea subrayando el título
    doc.line(x, y + 1, x + titleWidth, y + 1); // Dibuja la línea

    // Restablecer la fuente a normal
    doc.setFont("helvetica", "normal");

    // Agregar subtítulo "Reporte" al documento PDF
    const subtitle = "Reporte Nota Evaluación";
    const subtitleWidth = doc.getTextWidth(subtitle);
    const subtitleX = (doc.internal.pageSize.width - subtitleWidth) / 2; // Calcular posición X para centrar
    doc.text(subtitle, subtitleX, 30);

    // Agregar fecha al documento PDF
    const fecha = new Date();
    const dia = fecha.getDate();
    const mes = fecha.getMonth() + 1;
    const año = fecha.getFullYear();
    doc.setFontSize(9);
    doc.text(`Fecha: ${dia}/${mes}/${año}`, 10, 25);

    // Establecer la fuente en negrita
    doc.setFont("helvetica", "bold");

    // Definir el texto "Usuario: "
    const usuarioText = "Usuario: Personal Administrativo";

    // Calcular el ancho del texto
    const usuarioWidth = doc.getTextWidth(usuarioText);

    // Calcular la posición X para alinearlo a la derecha
    const usuarioX = doc.internal.pageSize.width - usuarioWidth - 10; // 10 mm de margen desde el borde derecho

    // Escribir el texto "Usuario:" en la posición calculada
    doc.text(usuarioText, usuarioX, 35);

    // Definir los datos de la tabla
    const userData = dataNG
      .map((iteb) => {
        // Asegurarse de convertir las notas a números
        const notaEva = parseFloat(iteb.nota_eva) || 0; // Convierte a número, si no es válido, usa 0
        const promedioSemestre = parseFloat(iteb.promedio_semestre) || 0; // Convierte a número, si no es válido, usa 0

        // Calcular el promedio de nota_eva y promedio_semestre
        const notaFinal = (notaEva + promedioSemestre) / 2;

        return {
          nombre: `${iteb.nombre} ${iteb.apellido}`,
          email: iteb.email,
          celular: iteb.celular,
          nota_eva: notaEva.toFixed(2), // Formatear a dos decimales
          promedio_semestre: promedioSemestre.toFixed(2), // Formatear a dos decimales
          nota_final: notaFinal.toFixed(2), // Formatear a dos decimales
        };
      })
      .sort((a, b) => a.nombre.localeCompare(b.nombre)) // Ordenar por nombre en orden alfabético ascendente
      .map((iteb, nb) => ({
        it: nb + 1, // Asignar el índice de forma ascendente después de ordenar
        ...iteb, // Desestructurar el resto de las propiedades
      }));

    // Definir las columnas de la tabla
    const columns = [
      { header: "N°", dataKey: "it" },
      { header: "Nombre Becario", dataKey: "nombre" },
      { header: "Correo", dataKey: "email" },
      { header: "Telf/Cel", dataKey: "celular" },
      { header: "Evaluación P.", dataKey: "nota_eva" },
      { header: "P. Semestre", dataKey: "promedio_semestre" }, // Cambiar a promedio_semestre
      { header: "Nota Final", dataKey: "nota_final" }, // Cambiar a nota_final
    ];

    // Agregar la tabla al documento PDF
    doc.autoTable(columns, userData, {
      startY: 40,
      margin: { top: 20, bottom: 20 },
      bodyStyles: { fontSize: 8 },
      headStyles: { fontSize: 9, fillColor: [10, 49, 67] },
    });

    // Agregar número de página al documento PDF
    let totalPages = doc.internal.getNumberOfPages();
    for (let i = 1; i <= totalPages; i++) {
      doc.setPage(i);
      doc.setFontSize(9);
      doc.text(
        `Página ${i} de ${totalPages}`,
        10,
        doc.internal.pageSize.height - 10
      );
    }

    // Convertir el documento PDF a blob
    const pdfBlob = doc.output("blob");

    // Abrir el PDF en una nueva ventana
    const url = URL.createObjectURL(pdfBlob);
    window.open(url, "_blank");
  };
  return (
    <div id="main_content">
      <div className="tra">
        <div className="tra title-form">
          <h2 className>Listado Becarios</h2>
        </div>
        <div className="frm">
          <Button color="primary" size="lg" onClick={generatePDF}>
            <FaIcons.FaFileDownload /> Reporte
          </Button>
        </div>
        <div className="frm containerInput">
          <Input
            className="form-control inputBuscar"
            size="lg"
            value={busqueda}
            placeholder="Búsqueda por Nombre, Apellido o Email"
            onChange={handleChangeB}
          />
          <Button className="btn btn-success" size="lg">
            <FaIcons.FaSearch /> Buscar
          </Button>
          <br />
        </div>

        <Table className="frm" responsive="sm" id="tabl">
          <thead id="stahead">
            <tr className="text-center">
              <th>N°</th>
              <th>
                <span onClick={() => handleSortingChange("nombre")}>
                  Nombre Becario
                  {sortField === "nombre" && order === "asc" ? (
                    <FaIcons.FaSortUp />
                  ) : sortField === "nombre" && order === "desc" ? (
                    <FaIcons.FaSortDown />
                  ) : (
                    <FaIcons.FaSort />
                  )}
                </span>
              </th>
              <th>
                <span onClick={() => handleSortingChange("email")}>
                  Correo
                  {sortField === "email" && order === "asc" ? (
                    <FaIcons.FaSortUp />
                  ) : sortField === "email" && order === "desc" ? (
                    <FaIcons.FaSortDown />
                  ) : (
                    <FaIcons.FaSort />
                  )}
                </span>
              </th>
              <th>
                <span onClick={() => handleSortingChange("celular")}>
                  Telf/Cel
                  {sortField === "celular" && order === "asc" ? (
                    <FaIcons.FaSortUp />
                  ) : sortField === "celular" && order === "desc" ? (
                    <FaIcons.FaSortDown />
                  ) : (
                    <FaIcons.FaSort />
                  )}
                </span>
              </th>
              <th>Acción</th>
              <th>
                <span onClick={() => handleSortingChange("nota_eva")}>
                  Evaluación P.
                  {sortField === "nota_eva" && order === "asc" ? (
                    <FaIcons.FaSortUp />
                  ) : sortField === "nota_eva" && order === "desc" ? (
                    <FaIcons.FaSortDown />
                  ) : (
                    <FaIcons.FaSort />
                  )}
                </span>
              </th>
              <th>
                <span onClick={() => handleSortingChange("nota_eva")}>
                  P. Semestre
                  {sortField === "nota_eva" && order === "asc" ? (
                    <FaIcons.FaSortUp />
                  ) : sortField === "nota_eva" && order === "desc" ? (
                    <FaIcons.FaSortDown />
                  ) : (
                    <FaIcons.FaSort />
                  )}
                </span>
              </th>
              <th>
                <span onClick={() => handleSortingChange("nota_eva")}>
                  Nota Final
                  {sortField === "nota_eva" && order === "asc" ? (
                    <FaIcons.FaSortUp />
                  ) : sortField === "nota_eva" && order === "desc" ? (
                    <FaIcons.FaSortDown />
                  ) : (
                    <FaIcons.FaSort />
                  )}
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedData.map((item, num2) => {
              // Asegurarse de convertir las notas a números
              const notaEva = parseFloat(item.nota_eva) || 0; // Convierte a número, si no es válido, usa 0
              const promedioSemestre = parseFloat(item.promedio_semestre) || 0; // Convierte a número, si no es válido, usa 0

              // Calcular el promedio de nota_eva y promedio_semestre
              const notaFinal = (notaEva + promedioSemestre) / 2;

              return (
                <tr className="text-center" key={item.id}>
                  <td>{num2 + 1}</td>
                  <td>
                    {item.nombre} {item.apellido}
                  </td>
                  <td>{item.email}</td>
                  <td>{item.celular}</td>
                  <td>
                    <Button
                      className="btn btn-secondary"
                      onClick={() => seleccionarUsuario(item)}>
                      <FaIcons.FaClipboardCheck />
                      &nbsp;EVALUAR
                    </Button>
                  </td>
                  <td>{notaEva.toFixed(2)}</td>
                  <td>{promedioSemestre.toFixed(2)}</td>
                  <td>
                    <strong>{notaFinal.toFixed(2)}</strong>{" "}
                    {/* Muestra el promedio con dos decimales */}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        {/*MODAL EVALUAR */}
        <Modal isOpen={modalEditar}>
          <ModalHeader className="header_mo">Evaluar Becario</ModalHeader>
          <ModalBody>
            <div className="form-group">
              <label>ID Becario: </label>
              <br />
              <input
                disabled
                type="text"
                className="form-control"
                name="nombre"
                value={usuarioSeleccionado && usuarioSeleccionado.id_bec}
              />
              <br />
              <div>
                <Container>
                  <Row>
                    <h5>1. Asistencia a las reuniones</h5>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Input
                          id="radio1"
                          type="radio"
                          name="p1"
                          value="25"
                          checked={fram1 === "25"}
                          onChange={cambioRadioFram1}
                        />
                        <Label for="radio1">Bueno</Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Input
                          id="radio2"
                          type="radio"
                          name="p1"
                          value="13"
                          checked={fram1 === "13"}
                          onChange={cambioRadioFram1}
                        />
                        <Label for="radio2">Regular</Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Input
                          id="radio3"
                          type="radio"
                          name="p1"
                          value="1"
                          checked={fram1 === "1"}
                          onChange={cambioRadioFram1}
                        />
                        <Label for="radio3">Malo</Label>
                      </FormGroup>
                      {isValid.p1 ? null : (
                        <span style={{ color: "red" }}>
                          Por favor seleccione una opción
                        </span>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <h5>2. Puntualidad</h5>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Input
                          id="radio4"
                          type="radio"
                          name="p2"
                          value="25"
                          checked={fram2 === "25"}
                          onChange={cambioRadioFram2}
                        />
                        <Label for="radio4">Bueno</Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Input
                          id="radio5"
                          type="radio"
                          name="p2"
                          value="13"
                          checked={fram2 === "13"}
                          onChange={cambioRadioFram2}
                        />
                        <Label for="radio5">Regular</Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Input
                          id="radio6"
                          type="radio"
                          name="p2"
                          value="1"
                          checked={fram2 === "1"}
                          onChange={cambioRadioFram2}
                        />
                        <Label for="radio6">Malo</Label>
                      </FormGroup>
                      {isValid.p2 ? null : (
                        <span style={{ color: "red" }}>
                          Por favor seleccione una opción
                        </span>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <h5>3. Compañerismo</h5>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Input
                          id="radio7"
                          type="radio"
                          name="p3"
                          value="25"
                          checked={fram3 === "25"}
                          onChange={cambioRadioFram3}
                        />
                        <Label for="radio7">Bueno</Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Input
                          id="radio8"
                          type="radio"
                          name="p3"
                          value="13"
                          checked={fram3 === "13"}
                          onChange={cambioRadioFram3}
                        />
                        <Label for="radio8">Regular</Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Input
                          id="radio9"
                          type="radio"
                          name="p3"
                          value="1"
                          checked={fram3 === "1"}
                          onChange={cambioRadioFram3}
                        />
                        <Label for="radio9">Malo</Label>
                      </FormGroup>
                      {isValid.p3 ? null : (
                        <span style={{ color: "red" }}>
                          Por favor seleccione una opción
                        </span>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <h5>4. Cumplimiento reglamento institucional</h5>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Input
                          id="radio10"
                          type="radio"
                          name="p4"
                          value="25"
                          checked={fram4 === "25"}
                          onChange={cambioRadioFram4}
                        />
                        <Label for="radio10">Bueno</Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Input
                          id="radio11"
                          type="radio"
                          name="p4"
                          value="13"
                          checked={fram4 === "13"}
                          onChange={cambioRadioFram4}
                        />
                        <Label for="radio11">Regular</Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Input
                          id="radio12"
                          type="radio"
                          name="p4"
                          value="1"
                          checked={fram4 === "1"}
                          onChange={cambioRadioFram4}
                        />
                        <Label for="radio12">Malo</Label>
                      </FormGroup>
                      {isValid.p4 ? null : (
                        <span style={{ color: "red" }}>
                          Por favor seleccione una opción
                        </span>
                      )}
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="success"
              size="lg"
              onClick={peticionPut}
              disabled={!fram1 || !fram2 || !fram3 || !fram4}>
              EVALUAR
            </Button>
            {"   "}
            <Button
              color="danger"
              size="lg"
              onClick={() => abrirCerrarModalEditar()}>
              Cancelar
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
}

export default Evalua;
