import React from "react";
import "../Bec/bec.scss";
import { Card, CardTitle, CardText, Col } from "reactstrap";
import { Link } from "react-router-dom";

const user = sessionStorage.getItem("iduser");
function Personal({ nombre }) {
  return (
    <div className="d-flex p-5 justify-content-center">
      <Col>
        <Card body className="frm text-center">
          <CardTitle tag="h4">Nota:</CardTitle>
          <CardText>
            <h2>Bienvenido: {user}</h2>
            <>
              <p>
                En caso de que no haya registrado sus datos personales,
                continuar con el registro.
              </p>
              <b>Por favor haga clic en el botón a continuación</b>
            </>
          </CardText>
          <Link to="/Personal/regper" className="btn" id="but">
            Continuar registro
          </Link>
        </Card>
      </Col>
    </div>
  );
}

export default Personal;
