import { Routes, Route } from "react-router-dom";
import ListUser from "./Pages/Admin/Crud";
import Administrador from "./Pages/Admin/dashboard";
import ListUserAdm from "./Pages/Admin/Crud-Adm"; // Corrige el nombre de la importación
import ListUserPat from "./Pages/Admin/Crud-Pat"; // Corrige el nombre de la importación
import Informe2 from "./Pages/Admin/informe2";
import Protected from "./utils/Protected";
import LoginBec from "./utils/loginBec";
import Becario from "./Pages/Bec/Index";
import Horarios1 from "./Pages/Bec/Horarios/Horarios1";
import Horarios2 from "./Pages/Bec/Horarios/Horarios2";
import Horarios3 from "./Pages/Bec/Horarios/Horarios3";
import Horarios4 from "./Pages/Bec/Horarios/Horarios4";
import Horarios5 from "./Pages/Bec/Horarios/Horarios5";
import Horarios6 from "./Pages/Bec/Horarios/Horarios6";
import Horarios7 from "./Pages/Bec/Horarios/Horarios7";
import Horarios8 from "./Pages/Bec/Horarios/Horarios8";
import Horarios9 from "./Pages/Bec/Horarios/Horarios9";
import Horarios10 from "./Pages/Bec/Horarios/Horarios10";
import NavbarBec from "./components/Navbarbec/NavbarBec";
import NavbarAdm from "./components/Navbaradm/NavbarAdm";
import NavbarPer from "./components/Navbarper/NavbarPer";
import RegComp from "./Pages/Bec/RegComp";
import ListBe from "./Pages/Per/Bec";
import ListPat from "./Pages/Per/Pat";
import Evalua from "./Pages/Per/Evalua";
import CargaDoc from "./Pages/Per/CargaDoc";
import Analisis from "./Pages/Per/Analisis";
import Personal from "./Pages/Per/Index";
import RegPer from "./Pages/Per/RegPer";
import Nota1 from "./Pages/Bec/Notas/Nota1";
import Nota2 from "./Pages/Bec/Notas/Nota2";
import Nota3 from "./Pages/Bec/Notas/Nota3";
import Nota4 from "./Pages/Bec/Notas/Nota4";
import Nota5 from "./Pages/Bec/Notas/Nota5";
import Nota6 from "./Pages/Bec/Notas/Nota6";
import Nota7 from "./Pages/Bec/Notas/Nota7";
import Nota8 from "./Pages/Bec/Notas/Nota8";
import Nota9 from "./Pages/Bec/Notas/Nota9";
import Nota10 from "./Pages/Bec/Notas/Nota10";

// const TIMEOUT_DURATION = 5 * 60 * 1000; // 5 minutos en milisegundos

function App() {
  // const { logoutSubmit, isAuthenticated } = useAuth(); // Obtén la función de logoutSubmit y el estado de autenticación
  // const [remainingTime, setRemainingTime] = useState(TIMEOUT_DURATION); // Estado para el tiempo restante

  // // Configurar el temporizador de inactividad
  // useIdleTimer(TIMEOUT_DURATION, logoutSubmit, setRemainingTime); // Usa el hook de inactividad

  // // Iniciar el temporizador solo si el usuario está autenticado
  // useEffect(() => {
  //   if (isAuthenticated) {
  //     setRemainingTime(TIMEOUT_DURATION); // Reiniciar el tiempo restante
  //   } else {
  //     setRemainingTime(TIMEOUT_DURATION); // Reiniciar el tiempo si el usuario no está autenticado
  //   }
  // }, [isAuthenticated]); // Ejecutar el efecto cuando cambie la autenticación // Función para formatear el tiempo restante en minutos y segundos
  // const formatTime = (time) => {
  //   const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
  //   const seconds = Math.floor((time % (1000 * 60)) / 1000);
  //   return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  // };

  return (
    <div className="flex">
      <div className="content w-100">
        {/* Mostrar el temporizador de inactividad solo si el usuario está autenticado */}
        {/* {isAuthenticated && (
          <div className="inactivity-timer">
            Tiempo restante para cierre de sesión: {formatTime(remainingTime)}
          </div>
        )} */}
        <Routes>
          <Route index exact path="/" element={<LoginBec />} />
          <Route path="/Administrador" element={<NavbarAdm />}>
            <Route
              path="Administrador"
              element={<Protected Component={Administrador} />}
            />
            <Route
              path="usuarios"
              element={<Protected Component={ListUser} />}
            />
            <Route
              path="usuarios-adm"
              element={<Protected Component={ListUserAdm} />}
            />
            <Route
              path="usuarios-pat"
              element={<Protected Component={ListUserPat} />}
            />
            <Route
              path="informe2"
              element={<Protected Component={Informe2} />}
            />
            {/* Otras rutas */}
            <Route path="*" element={<h1>Page not found</h1>} />
          </Route>
          <Route path="/Becario" element={<NavbarBec />}>
            <Route path="Becario" element={<Protected Component={Becario} />} />
            <Route
              path="horarios1"
              element={<Protected Component={Horarios1} />}
            />
            <Route
              path="horarios2"
              element={<Protected Component={Horarios2} />}
            />
            <Route
              path="horarios3"
              element={<Protected Component={Horarios3} />}
            />
            <Route
              path="horarios4"
              element={<Protected Component={Horarios4} />}
            />
            <Route
              path="horarios5"
              element={<Protected Component={Horarios5} />}
            />
            <Route
              path="horarios6"
              element={<Protected Component={Horarios6} />}
            />
            <Route
              path="horarios7"
              element={<Protected Component={Horarios7} />}
            />
            <Route
              path="horarios8"
              element={<Protected Component={Horarios8} />}
            />
            <Route
              path="horarios9"
              element={<Protected Component={Horarios9} />}
            />
            <Route
              path="horarios10"
              element={<Protected Component={Horarios10} />}
            />
            <Route path="regcomp" element={<Protected Component={RegComp} />} />
            <Route path="nota1" element={<Protected Component={Nota1} />} />
            <Route path="nota2" element={<Protected Component={Nota2} />} />
            <Route path="nota3" element={<Protected Component={Nota3} />} />
            <Route path="nota4" element={<Protected Component={Nota4} />} />
            <Route path="nota5" element={<Protected Component={Nota5} />} />
            <Route path="nota6" element={<Protected Component={Nota6} />} />
            <Route path="nota7" element={<Protected Component={Nota7} />} />
            <Route path="nota8" element={<Protected Component={Nota8} />} />
            <Route path="nota9" element={<Protected Component={Nota9} />} />
            <Route path="nota10" element={<Protected Component={Nota10} />} />
            {/* Otras rutas */}
            <Route path="*" element={<h1>Page not found</h1>} />
          </Route>
          <Route path="/Personal" element={<NavbarPer />}>
            <Route
              path="Personal"
              element={<Protected Component={Personal} />}
            />
            <Route path="becarios" element={<Protected Component={ListBe} />} />
            <Route path="patro" element={<Protected Component={ListPat} />} />
            <Route path="evaluar" element={<Protected Component={Evalua} />} />
            <Route path="cargar" element={<Protected Component={CargaDoc} />} />
            <Route
              path="analisis"
              element={<Protected Component={Analisis} />}
            />
            <Route path="regper" element={<Protected Component={RegPer} />} />
            {/* Otras rutas */}
            <Route path="*" element={<h1>Page not found</h1>} />
          </Route>
          <Route path="*" element={<h1>Page not found</h1>} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
