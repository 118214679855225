import React, { useEffect, useRef, useState } from "react";
import "../Bec/bec.scss";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import html2canvas from "html2canvas";
import logos from "../../components/img/base/111.png";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  CardText,
  CardSubtitle,
  CardGroup,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  Input,
  Alert,
} from "reactstrap";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  LineElement,
  PointElement,
} from "chart.js";
import { Bar, Doughnut, Line } from "react-chartjs-2";
import axios from "axios";
import * as FaIcons from "react-icons/fa";
import { Link } from "react-router-dom";

const Administrador = () => {
  ////GET BECARIOS AÑOS
  const [anio, setAnio] = useState([]);
  const [anioB, setAnioB] = useState([]);
  const [incrementos, setIncrementos] = useState([]);
  const [estimado, setEstimado] = useState([]);
  ///Listar años becarios
  const peticionGet1bec = async () => {
    await axios
      .get(`https://bqef-bo.com/api/A/anioAB.php`)
      .then((response) => {
        var respuesta = response.data;
        var auxAnio = [],
          auxAnioB = [];
        respuesta.map((elemento) => {
          auxAnio.push(elemento.anio);
          auxAnioB.push(elemento.Incritos);
        });
        setAnio(auxAnio);
        setAnioB(auxAnioB);
        const incrementos = calcularIncremento(auxAnioB);
        setIncrementos(incrementos);
        const estimado = calcularEstimado(auxAnioB, incrementos);
        setEstimado(estimado); // Actualizar el estado con el estimado poblacional
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const calcularIncremento = (anioB) => {
    const incrementos = [];
    for (let i = 1; i < anioB.length; i++) {
      const incremento = ((anioB[i] - anioB[i - 1]) / anioB[i - 1]) * 100;
      incrementos.push(incremento);
    }
    return incrementos;
  };

  const calcularEstimado = (anioB, incrementos) => {
    const estimado = [];
    const promedioIncremento =
      incrementos.reduce((a, b) => a + b, 0) / incrementos.length;
    let ultimoValor = anioB[anioB.length - 1];
    for (let i = 0; i < 30; i++) {
      // Predecir los próximos 5 años
      const estimadoValor = ultimoValor * (1 + promedioIncremento / 100);
      estimado.push(estimadoValor);
      ultimoValor = estimadoValor; // Actualizar el último valor para la próxima iteración
    }
    return estimado;
  };

  ////Listar region becarios
  const [ciudad, setCiudad] = useState([]);
  const [region, setRegion] = useState([]);

  const peticionGetbecC = async () => {
    await axios
      .get(`https://bqef-bo.com/api/A/ciuCB.php`)
      .then((response) => {
        //console.log(response.data);;
        var respuesta = response.data;
        var auxCiu = [],
          auxReg = [];
        respuesta.map((elemento) => {
          auxCiu.push(elemento.ciudad);
          auxReg.push(elemento.region);
        });
        setCiudad(auxCiu);
        setRegion(auxReg);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  ////Listar becarios
  const [dataB, setDataB] = useState([]);

  const peticionGetB = async () => {
    await axios
      .get(`https://bqef-bo.com/api/A/dashBE.php`)
      .then((response) => {
        //console.log(response.data);;
        setDataB(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  ////GET PERSONAL
  const [dataP, setDataP] = useState([]);
  const peticionGetP = async () => {
    await axios
      .get(`https://bqef-bo.com/api/A/dashPER.php`)
      .then((response) => {
        //console.log(response.data);;
        setDataP(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const [dataF, setDataF] = useState([]);
  const [dataFI, setDataFI] = useState([]);
  const [dataL, setDataL] = useState([]);

  ///Mostrar fecha cargado informe
  useEffect(() => {
    const peticionGetFI = async () => {
      try {
        const response = await axios.get(`https://bqef-bo.com/api/A/fecEI.php`);
        //console.log(response.data);; // Imprime la respuesta en la consola
        setDataFI(response.data);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    ////Mostrar Fecha Entrega Informe
    const peticionGetF = async () => {
      try {
        const response = await axios.get(`https://bqef-bo.com/api/A/fecEP.php`);
        //console.log(response.data);; // Imprime la respuesta en la consola
        setDataF(response.data);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
    ////Listar Logs de eventos
    async function peticionGetLog() {
      const res = await axios.get(`https://bqef-bo.com/api/A/logLM.php`);
      setDataL(res.data);
      // console.log(res.data);
    }
    peticionGetFI();
    peticionGetF();
    peticionGetLog();
  }, []);

  ////Listar informes entregados
  const [dataI, setDataI] = useState([]);
  const peticionGetI = async () => {
    await axios
      .get(`https://bqef-bo.com/api/A/dashINF.php`)
      .then((response) => {
        //console.log(response.data);;
        setDataI(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    peticionGet1bec();
    peticionGetbecC();
    peticionGetB();
    peticionGetP();
    peticionGetI();
  }, []);
  ////GRAFICO BARRAS
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    ArcElement,
    Tooltip,
    Legend
  );
  const data = {
    labels: anio,
    datasets: [
      {
        label: "Inscritos",
        backgroundColor: "rgba(49, 51, 124, 0.7)",
        borderColor: "rgb(49, 51, 124)",
        borderWidth: 3,
        hoverBackgroundColor: "rgba(49, 51, 124, 0.8)",
        hoverBorderColor: "rgba(49, 51, 124)",
        data: anioB,
      },
    ],
  };
  const opciones = {
    maintainAspectRatio: false,
    responsive: true,
    ticks: {
      font: {
        size: 15,
        weight: "bold",
      },
    },
    plugins: {
      legend: {
        labels: {
          font: {
            size: 15,
            weight: "bold",
          },
        },
      },
    },
  };
  /////GRAFICO PIE
  const dataPie = {
    labels: ciudad,
    datasets: [
      {
        label: "# miembros",
        data: region,
        backgroundColor: [
          "rgba(217, 83, 79, 0.6)",
          "rgba(10, 49, 67, 0.6)",
          "rgba(255, 206, 86, 0.6)",
          "rgba(75, 192, 192, 0.6)",
          "rgba(153, 102, 255, 0.6)",
          "rgba(255, 159, 64, 0.6)",
        ],
        borderColor: [
          "rgba(217, 83, 79, 0.8)",
          "rgba(10, 49, 67, 0.8)",
          "rgba(255, 206, 86, 0.8)",
          "rgba(75, 192, 192, 0.8)",
          "rgba(153, 102, 255, 0.8)",
          "rgba(255, 159, 64, 0.8)",
        ],
        hoverBackgroundColor: [
          "rgba(217, 83, 79, 0.9)",
          "rgba(10, 49, 67, 0.9)",
          "rgba(255, 206, 86, 0.9)",
          "rgba(75, 192, 192, 0.9)",
          "rgba(153, 102, 255, 0.9)",
          "rgba(255, 159, 64, 0.9)",
        ],
        hoverBorderColor: [
          "rgba(217, 83, 79)",
          "rgba(10, 49, 67)",
          "rgba(255, 206, 86)",
          "rgba(75, 192, 192)",
          "rgba(153, 102, 255)",
          "rgba(255, 159, 64)",
        ],
        borderWidth: 3,
      },
    ],
  };
  const opcionesPie = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        labels: {
          font: {
            size: 15,
            weight: "bold",
          },
        },
      },
    },
  };
  /////dataFI
  const [modalVer, setModalVer] = useState(false);
  const abrirCerrarModalVer = () => {
    setModalVer(!modalVer);
  };
  ////
  const closeBtn = (
    <Button className="close" onClick={() => abrirCerrarModalVer()}>
      &times;
    </Button>
  );
  ////Modal REPORTE INFORMES
  const [modalVerInf, setModalVerInf] = useState(false);
  const abrirCerrarModalVerInf = () => {
    setModalVerInf(!modalVerInf);
  };
  const toggleI = () => setModalVerInf(!modalVerInf);
  const closeBtn3 = (
    <Button className="close" onClick={() => abrirCerrarModalVerInf()}>
      &times;
    </Button>
  );

  ////Modal Proyeccion
  const [modalProy, setModalProy] = useState(false);
  const abrirCerrarModalProy = () => {
    setModalProy(!modalProy);
  };
  /////proyección
  ChartJS.register(CategoryScale, LinearScale, LineElement, PointElement);
  const datosGrafico = {
    labels: anio,
    datasets: [
      {
        label: "Registros actuales",
        data: anioB,
        borderColor: "rgb(49, 51, 124, 0.5)",
        backgroundColor: "rgb(49, 51, 124, 0.5)",
        pointBackgroundColor: "rgb(49, 51, 124, 0.8)",
        pointBorderColor: "rgb(49, 51, 124, 0.5)",
        pointBorderWidth: 1,
        pointRadius: 3,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgb(49, 51, 124, 0.2)",
        pointHoverBorderColor: "rgb(49, 51, 124, 0.5)",
      },
      {
        label: "Incremento poblacional para el siguiente año",
        data: incrementos,
        borderColor: "rgba(54, 162, 235, 1)",
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        pointBackgroundColor: "rgba(54, 162, 235, 1)",
        pointBorderColor: "rgba(54, 162, 235, 1)",
        pointBorderWidth: 1,
        pointRadius: 3,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(54, 162, 235, 1)",
        pointHoverBorderColor: "rgba(54, 162, 235, 1)",
      },
      {
        label: "Estimado Poblacional",
        data: estimado,
        backgroundColor: "rgba(255, 206, 86, 0.2)",
        borderColor: "rgba(255, 206, 86, 1)",
        borderWidth: 1,
      },
    ],
  };

  const opcionesGrafico = {
    title: {
      display: true,
      text: "Proyección de cantidad de registros para el siguiente año",
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };
  ////Listar Notas Gestión Becarios
  const [dataNG, setDataNG] = useState([]);
  const [datosGraficoN, setDatosGrafico] = useState({});
  const [opcionesGraficoN, setOpcionesGrafico] = useState({});
  const chartRef = useRef(null); // Referencia para el gráfico

  useEffect(() => {
    // Cargar datos desde un API o un archivo JSON
    fetch("https://bqef-bo.com/api/A/dashNotB.php")
      .then((res) => res.json())
      .then((data) => setDataNG(data));
  }, []);

  const [selectedUser, setSelectedUser] = useState("");

  const handleSelectUser = (e) => {
    setSelectedUser(e.target.value);
  };
  // const [selectedUser, setSelectedUser] = useState("");
  const [dataS, setDataS] = useState([]);

  useEffect(() => {
    setDataS(dataNG.filter((iteb) => iteb.id_bec === selectedUser));
  }, [selectedUser]);

  useEffect(() => {
    // Filtrar datos para el usuario seleccionado
    const filteredData = dataNG.filter((iteb) => iteb.id_bec === selectedUser);

    // Crear datos para el gráfico
    const data = {
      labels: filteredData.map((iteb) => iteb.anio), // Año en el eje X
      datasets: [
        {
          label: "Notas Gestión", // Etiqueta del conjunto de datos
          data: filteredData.map((iteb) => iteb.promedio_semestre), // Nota A1 en el eje Y
          borderColor: "rgb(49, 51, 124)", // Color de la línea
          backgroundColor: "rgba(217, 83, 79, 0.6)", //
          borderWidth: 2, // Ancho de la línea
        },
      ],
    };

    // Crear opciones para el gráfico
    const options = {
      scales: {
        y: {
          beginAtZero: true, // Comienza el eje Y en 0
        },
      },
    };

    setDatosGrafico(data);
    setOpcionesGrafico(options);
  }, [selectedUser, dataNG]);
  /////seleccionar usuario
  const uniqueUsers = [];

  // Utiliza un Set para rastrear los IDs de los usuarios ya agregados
  const userIds = new Set();

  dataNG.forEach((iteb) => {
    // Solo agrega el usuario si su ID no ha sido agregado antes
    if (!userIds.has(iteb.id_bec)) {
      uniqueUsers.push({
        id_bec: iteb.id_bec,
        nombre: iteb.nombre,
        apellido: iteb.apellido,
      });
      userIds.add(iteb.id_bec); // Marca el ID como agregado
    }
  });
  ////Abrir modal proyeccion
  const [alertVisible, setAlertVisible] = useState(true);
  const onDismiss = () => setAlertVisible(false);
  const abrirCerrarModalProyec = () => {
    // Aquí va el código para abrir el modalProy
    setModalProy(!modalProy);
  };
  ////Modal REPORTE NOTAS
  const [modalVerNot, setModalVerNot] = useState(false);
  const abrirCerrarModalVerNot = () => {
    setModalVerNot(!modalVerNot);
  };
  const toggle = () => setModalVerNot(!modalVerNot);
  const closeBtn2 = (
    <Button className="close" onClick={() => abrirCerrarModalVerNot()}>
      &times;
    </Button>
  );
  ///GENERAR PDF ESTADO ACADEMICO
  const generatePDF = async (userId) => {
    console.log(`Generando PDF para el usuario ${userId}`);
    const doc = new jsPDF();

    // Agregar logo al documento PDF
    doc.addImage(logos, "PNG", 10, 10, 20, 10);

    // Establecer la fuente en negrita
    doc.setFont("helvetica", "bold");

    // Agregar título "FONDO DE EDUCACIÓN" al documento PDF
    doc.setFontSize(12);
    const title = "FONDO DE EDUCACION DE CUAQUEROS BOLIVIANOS";
    const titleWidth = doc.getTextWidth(title);
    const x = (doc.internal.pageSize.width - titleWidth) / 2; // Calcular posición X para centrar
    const y = 20; // Posición Y del título

    // Escribir el título
    doc.text(title, x, y);

    // Dibujar la línea subrayando el título
    doc.line(x, y + 1, x + titleWidth, y + 1); // Dibuja la línea

    // Restablecer la fuente a normal
    doc.setFont("helvetica", "normal");

    // Agregar subtítulo "Reporte" al documento PDF
    doc.setFontSize(12);
    const subtitle = "Reporte Acádemico: " + userId;
    const subtitleWidth = doc.getTextWidth(subtitle);
    const subtitleX = (doc.internal.pageSize.width - subtitleWidth) / 2; // Calcular posición X para centrar
    doc.text(subtitle, subtitleX, 38);

    // Agregar fecha al documento PDF
    const fecha = new Date();
    const dia = fecha.getDate();
    const mes = fecha.getMonth() + 1;
    const año = fecha.getFullYear();
    doc.setFontSize(9);
    doc.text(`Fecha: ${dia}/${mes}/${año}`, 10, 25);

    // Establecer la fuente en negrita
    doc.setFont("helvetica", "bold");
    doc.text("Usuario: Administrador Sistema", 145, 30);

    // Definir los datos de la tabla de notas
    const userData = dataS
      .filter(
        (iteb) =>
          iteb.semestre !== null &&
          iteb.semestre !== "" &&
          iteb.semestre !== "null"
      ) // Filtrar registros donde semestre no es null ni vacío
      .map((iteb, nb) => ({
        it: nb + 1,
        nom_b: `${iteb.nombre} ${iteb.apellido}`,
        nota: parseFloat(iteb.promedio_semestre), // Convertir a número
        gest: iteb.anio,
        sem: iteb.semestre,
        nota_e: iteb.nota_eva,
      }));

    // Filtrar notas que son números válidos
    const validNotas = userData.filter((iteb) => !isNaN(iteb.nota));

    // Calcular el promedio de calificaciones
    const totalNotas = validNotas.reduce((acc, curr) => acc + curr.nota, 0);
    const cantidadNotas = validNotas.length;
    const promedio = cantidadNotas > 0 ? totalNotas / cantidadNotas : 0;

    // // Mostrar el promedio en consola o en el documento PDF
    // console.log(`El promedio de calificaciones es: ${promedio.toFixed(2)}`);

    // Definir las columnas de la tabla de notas
    const columns = [
      { header: "N°", dataKey: "it" },
      { header: "Nombre Becario", dataKey: "nom_b" },
      { header: "N. Académica", dataKey: "nota" },
      { header: "Gestión", dataKey: "gest" },
      { header: "Semestre", dataKey: "sem" },
      { header: "N. Evaluación", dataKey: "nota_e" },
    ];

    // Agregar la tabla de notas al documento PDF
    doc.autoTable(columns, userData, {
      startY: 40,
      margin: { top: 20, bottom: 20 },
      bodyStyles: { fontSize: 9 },
      headStyles: { fontSize: 10, fillColor: [10, 49, 67] },
    });

    // Si deseas agregar el promedio a la tabla PDF
    doc.text(
      `Promedio de Calificaciones: ${promedio.toFixed(2)}`,
      14,
      doc.autoTable.previous.finalY + 5
    );

    // Obtener todos los informes del becario
    const informesResponse = await fetch(
      `https://bqef-bo.com/api/A/cruCInf.php`
    );
    const informesData = await informesResponse.json();

    // Filtrar los informes para el usuario seleccionado
    const informesUserData = informesData
      .filter((inform) => inform.id_bec === userId)
      .map((inform, index) => ({
        it: index + 1,
        nom_usu: inform.nom_usu,
        nom_doc: inform.nom_doc,
        f_cargado: inform.f_cargado,
      }));
    // Agregar subtítulo "Reporte" al documento PDF
    doc.setFont("helvetica", "normal");
    doc.setFontSize(12);
    const subtitle2 = "Reporte Informes: " + userId;
    const subtitleWidth2 = doc.getTextWidth(subtitle2);
    const subtitleX2 = (doc.internal.pageSize.width - subtitleWidth2) / 2; // Calcular posición X para centrar
    const startY2 = doc.autoTable.previous.finalY + 13;
    doc.text(subtitle2, subtitleX2, startY2);
    // Definir las columnas de la tabla de informes
    const informesColumns = [
      { header: "N°", dataKey: "it" },
      { header: "Nombre becario", dataKey: "nom_usu" },
      { header: "Nombre Documento", dataKey: "nom_doc" },
      { header: "Fecha", dataKey: "f_cargado" },
    ];

    // Agregar la tabla de informes al documento PDF
    doc.autoTable(informesColumns, informesUserData, {
      startY: doc.autoTable.previous.finalY + 15, // Ajusta la posición Y según la tabla anterior
      margin: { top: 20, bottom: 20 },
      bodyStyles: { fontSize: 9 },
      headStyles: { fontSize: 10, fillColor: [10, 49, 67] },
    });

    // Capturar el gráfico y agregarlo al PDF
    html2canvas(chartRef.current).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const imgWidth = 180; // Ajusta el ancho del gráfico
      const imgHeight = (canvas.height * imgWidth) / canvas.width; // Mantiene la proporción

      // Calcular la posición X para centrar el gráfico
      const xCenter = (doc.internal.pageSize.width - imgWidth) / 2;

      // Agregar el gráfico al PDF, después de la tabla de informes
      doc.addImage(
        imgData,
        "PNG",
        xCenter,
        doc.autoTable.previous.finalY + 10,
        imgWidth,
        imgHeight
      );

      // Agregar número de página al documento PDF
      var totalPages = doc.internal.getNumberOfPages();
      for (let i = 1; i <= totalPages; i++) {
        doc.setPage(i);
        doc.setFontSize(9);
        doc.text(
          `Página ${i} de ${totalPages}`,
          10,
          doc.internal.pageSize.height - 10
        );
      }

      // Convertir el documento PDF a blob
      const pdfBlob = doc.output("blob");

      // Abrir el PDF en una nueva ventana
      const url = URL.createObjectURL(pdfBlob);
      window.open(url, "_blank");
    });
  };
  ///GENERAR PDF INFORME
  const generatePDFI = () => {
    // Crear un nuevo documento PDF en orientación horizontal (landscape)
    const doc = new jsPDF("landscape");

    // Agregar logo al documento PDF
    doc.addImage(logos, "PNG", 10, 10, 20, 10);

    // Establecer la fuente en negrita
    doc.setFont("helvetica", "bold");

    // Agregar título "FONDO DE EDUCACIÓN" al documento PDF
    doc.setFontSize(12);
    const title = "FONDO DE EDUCACION DE CUAQUEROS BOLIVIANOS";
    const titleWidth = doc.getTextWidth(title);
    const x = (doc.internal.pageSize.width - titleWidth) / 2; // Calcular posición X para centrar
    const y = 20; // Posición Y del título

    // Escribir el título
    doc.text(title, x, y);

    // Dibujar la línea subrayando el título
    doc.line(x, y + 1, x + titleWidth, y + 1); // Dibuja la línea

    // Restablecer la fuente a normal
    doc.setFont("helvetica", "normal");

    // Agregar subtítulo "Reporte" al documento PDF
    const subtitle = "Reporte Informes";
    const subtitleWidth = doc.getTextWidth(subtitle);
    const subtitleX = (doc.internal.pageSize.width - subtitleWidth) / 2; // Calcular posición X para centrar
    doc.text(subtitle, subtitleX, 30);

    // Agregar fecha al documento PDF
    const fecha = new Date();
    const dia = fecha.getDate();
    const mes = fecha.getMonth() + 1;
    const año = fecha.getFullYear();
    doc.setFontSize(9);
    doc.text(`Fecha: ${dia}/${mes}/${año}`, 10, 25);

    // Establecer la fuente en negrita
    doc.setFont("helvetica", "bold");

    // Definir el texto "Usuario: "
    const usuarioText = "Usuario: Personal Administrativo";

    // Calcular el ancho del texto
    const usuarioWidth = doc.getTextWidth(usuarioText);

    // Calcular la posición X para alinearlo a la derecha
    const usuarioX = doc.internal.pageSize.width - usuarioWidth - 10; // 10 mm de margen desde el borde derecho

    // Escribir el texto "Usuario:" en la posición calculada
    doc.text(usuarioText, usuarioX, 35);

    const userData = [];

    // Recorrer dataF y dataFI para llenar tableData
    dataF.forEach((itemF) => {
      const fechaActual = new Date(itemF.fecha);

      dataFI.forEach((item, n1) => {
        const fechaDocumento = new Date(item.f_cargado);
        const fnull = new Date(0);

        let estado = "";
        let analisis = ""; // Nueva variable para el análisis

        if (fechaDocumento.toISOString() === fechaActual.toISOString()) {
          estado = "ENTREGADO";
          analisis = "Aprobado"; // Asignar texto correspondiente
        } else if (fechaDocumento > fechaActual) {
          estado = "RETRASO";
          analisis = "Advertencia"; // Asignar texto correspondiente
        } else if (fechaDocumento.toISOString() === fnull.toISOString()) {
          estado = "PENDIENTE";
          analisis = "Tomar en cuenta retiro"; // Asignar texto correspondiente
        } else {
          estado = "ENTREGADO";
          analisis = "Aprobado"; // Asignar texto correspondiente
        }

        // Agregar los datos a la tabla
        userData.push([
          n1 + 1,
          item.responsable,
          item.nom_doc,
          item.f_cargado,
          estado,
          analisis, // Agregar el análisis a la fila
        ]);
      });
    });

    // Definir los encabezados de la tabla
    const headers = [
      "#",
      "Responsable",
      "Nombre del Documento",
      "Fecha Cargado",
      "Estado",
      "Análisis Sist.",
    ];

    // Agregar encabezados y datos a la tabla
    doc.autoTable({
      head: [headers],
      body: userData,
      didParseCell: (data) => {
        // Solo cambiar el fondo de la celda del estado
        if (data.column.index === 4) {
          // Verificar que estamos en la columna de estado
          const estado = data.cell.raw; // Obtener el valor de la celda
          if (estado === "ENTREGADO") {
            data.cell.styles.fillColor = [46, 139, 87]; // RGB para el color
            data.cell.styles.textColor = [255, 255, 255]; // Texto en blanco
          } else if (estado === "RETRASO") {
            data.cell.styles.fillColor = [219, 146, 46]; // RGB para el color
            data.cell.styles.textColor = [255, 255, 255]; // Texto en blanco
          } else if (estado === "PENDIENTE") {
            data.cell.styles.fillColor = [205, 92, 92]; // RGB para el color
            data.cell.styles.textColor = [255, 255, 255]; // Texto en blanco
          }
        }

        // Cambiar el color del texto en la columna de análisis
        if (data.column.index === 5) {
          // Verificar que estamos en la columna de análisis
          const analisis = data.cell.raw; // Obtener el valor de la celda
          if (analisis === "Aprobado") {
            data.cell.styles.textColor = [0, 128, 0];
          } else if (analisis === "Advertencia") {
            data.cell.styles.textColor = [219, 146, 46];
          } else if (analisis === "Tomar en cuenta retiro") {
            data.cell.styles.textColor = [255, 0, 0];
          }
        }
      },
      startY: 40,
      margin: { top: 20, bottom: 20 },
      bodyStyles: { fontSize: 8 },
      headStyles: { fontSize: 9, fillColor: [10, 49, 67] },
    });

    // Agregar número de página al documento PDF
    let totalPages = doc.internal.getNumberOfPages();
    for (let i = 1; i <= totalPages; i++) {
      doc.setPage(i);
      doc.setFontSize(9);
      doc.text(
        `Página ${i} de ${totalPages}`,
        10,
        doc.internal.pageSize.height - 10
      );
    }

    // Convertir el documento PDF a blob
    const pdfBlob = doc.output("blob");

    // Abrir el PDF en una nueva ventana
    const url = URL.createObjectURL(pdfBlob);
    window.open(url, "_blank");
  };
  return (
    <div id="main_content">
      <div className="tra">
        <CardGroup>
          <Card
            color=""
            style={{
              color: "black",
              width: "18rem",
            }}>
            <div className="row g-0">
              <CardBody className="col-md-4">
                <CardTitle tag="h5">Cuentas</CardTitle>
                <CardSubtitle className="mb-2 text-muted" tag="h6">
                  Becarios
                </CardSubtitle>
                <CardText>
                  {dataB.map((item) => (
                    <b key={item.id} style={{ color: "rgb(33 33 185)" }}>
                      {item.CBec}
                    </b>
                  ))}
                </CardText>
                <Button
                  className="btn-success"
                  onClick={() => abrirCerrarModalVerNot()}>
                  Ver
                </Button>
              </CardBody>
              <CardBody
                className="col-md-8"
                style={{
                  width: "70px",
                  backgroundColor: "rgba(217, 83, 79, 0.7)",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "8rem",
                  boxSizing: "border-box",
                }}>
                <FaIcons.FaUserGraduate />
              </CardBody>
            </div>
          </Card>

          <Card
            color=""
            style={{
              color: "black",
              width: "18rem",
            }}>
            <div className="row g-0">
              <CardBody className="col-md-4">
                <CardTitle tag="h5">Cuentas</CardTitle>
                <CardSubtitle className="mb-2 text-muted" tag="h6">
                  Personal
                </CardSubtitle>
                <CardText>
                  {dataP.map((item) => (
                    <b key={item.id} style={{ color: "rgb(33 33 185)" }}>
                      {item.CPer}
                    </b>
                  ))}
                </CardText>
                <Link
                  to="/Administrador/usuarios-adm"
                  className="btn btn-success">
                  Ver
                </Link>
              </CardBody>
              <CardBody
                className="col-md-8"
                style={{
                  width: "70px",
                  backgroundColor: "rgba(217, 83, 79, 0.7)",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "8rem",
                  boxSizing: "border-box",
                }}>
                <FaIcons.FaUserTie />
              </CardBody>
            </div>
          </Card>
          <Card
            color=""
            style={{
              color: "black",
              width: "18rem",
            }}>
            <div className="row g-0">
              <CardBody className="col-md-4">
                <CardTitle tag="h5">Informes</CardTitle>
                <CardSubtitle className="mb-2 text-muted" tag="h6">
                  Entregados
                </CardSubtitle>
                <CardText>
                  {dataI.map((item) => (
                    <b key={item.id_doc} style={{ color: "rgb(33 33 185)" }}>
                      {item.CInf}
                    </b>
                  ))}
                </CardText>
                <Button
                  className="btn-success"
                  onClick={() => abrirCerrarModalVerInf()}>
                  Ver
                </Button>
              </CardBody>
              <CardBody
                className="col-md-8"
                style={{
                  width: "70px",
                  backgroundColor: "rgba(217, 83, 79, 0.7)",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "8rem",
                  boxSizing: "border-box",
                }}>
                <FaIcons.FaReadme />
              </CardBody>
            </div>
          </Card>
          <Card
            color=""
            style={{
              color: "black",
              width: "18rem",
            }}>
            <div className="row g-0">
              <CardBody className="col-md-4">
                <CardTitle tag="h5">Fecha Entrega</CardTitle>
                <CardSubtitle className="mb-2 text-muted" tag="h6">
                  Informes
                </CardSubtitle>
                <CardText>
                  {dataF.map((item) => (
                    <b key={item.id_fech} style={{ color: "rgb(33 33 185)" }}>
                      {item.fech_lit}
                    </b>
                  ))}
                </CardText>
                <Button disabled className="btn-success">
                  Ver
                </Button>
              </CardBody>
              <CardBody
                className="col-md-8"
                style={{
                  width: "70px",
                  backgroundColor: "rgba(217, 83, 79, 0.7)",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "8rem",
                  boxSizing: "border-box",
                }}>
                <FaIcons.FaRegCalendarCheck />
              </CardBody>
            </div>
          </Card>
        </CardGroup>
        {/* // */}
        <Row>
          <Col sm="4">
            <Card body>
              <CardTitle tag="h5">Histórico de registros:</CardTitle>
              <CardText
                className="text-center"
                style={{ color: "rgb(33 33 185)" }}>
                <b>Becarios</b>
              </CardText>
              <div style={{ width: "100%", height: "400px" }}>
                <Bar data={data} options={opciones} />
              </div>
            </Card>
          </Col>
          <Col sm="4">
            <Card body>
              <CardTitle tag="h5">Registro según región:</CardTitle>
              <CardText
                className="text-center"
                style={{ color: "rgb(33 33 185)" }}>
                <b>Becarios</b>
              </CardText>
              <div style={{ width: "100%", height: "400px" }}>
                <Doughnut data={dataPie} options={opcionesPie} />
              </div>
            </Card>
          </Col>
          <Col sm="4">
            <Card body>
              <CardTitle tag="h5">Actividades recientes:</CardTitle>
              <div className="table-responsive" style={{ height: "450px" }}>
                <Table id="tabl">
                  <thead id="stahead">
                    <tr className="text-center">
                      <th>#</th>
                      <th>Usuario</th>
                      <th>Detalle</th>
                      <th>Fecha</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataL.map((usr, nu) => (
                      <tr className="text-center" key={usr.id_l}>
                        <td>{nu + 1}</td>
                        <td>{usr.usr_mod}</td>
                        <td>{usr.detalle}</td>
                        <td>{usr.fechaA}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
      <Alert
        color="info"
        isOpen={alertVisible}
        toggle={onDismiss}
        style={{
          maxWidth: "80vw", // utiliza unidad relativa vw para ancho
          position: "absolute",
          top: "50%",
          right: "0%",
          transform: "translate(-50%, -50%)",
          animation: "slideIn 0.8s forwards",
        }}>
        <span
          style={{
            cursor: "pointer",
            display: "flex", // Agregamos display: flex para alinear elementos
            alignItems: "center", // Centramos verticalmente los elementos
          }}
          onClick={() => abrirCerrarModalProyec()}>
          <FaIcons.FaChartLine />
          &nbsp; PROYECCIÓN &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </Alert>
      {/* Modal VER */}
      <Modal isOpen={modalVer} size="xl">
        <ModalHeader className="header_m" close={closeBtn}>
          Reporte Entrega Informes
        </ModalHeader>
        <ModalBody>
          <Card>
            <CardBody className="p-0">
              <Row className="justify-content-center">
                <object
                  data={"https://bqef-bo.com/api/PDF/reporte_pdf_Informes.php"}
                  type="application/pdf"
                  width="400"
                  height="600">
                  <div
                    style={{
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                    }}></div>
                </object>
              </Row>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>

      {/* Modal VER REPORTE INFORMES*/}
      <Modal isOpen={modalVerInf} size="xl">
        <ModalHeader className="header_mo" toggle={toggleI} close={closeBtn3}>
          Estado Informes Entregados
        </ModalHeader>
        <div className="frm">
          <Button color="primary" size="lg" onClick={generatePDFI}>
            <FaIcons.FaFileDownload /> Reporte
          </Button>
        </div>
        <ModalBody>
          <Table id="tabl">
            <thead id="stahead">
              <tr className="text-center tra title-form">
                <th>N°</th>
                <th>Nombre Becario</th>
                <th>N. Documento</th>
                <th>Fecha E.</th>
                <th>Estado</th>
              </tr>
            </thead>
            <tbody>
              {dataF.map((itemF) => (
                <>
                  <tr key={itemF.id_fech}></tr>
                  {dataFI.map((item, n1) => {
                    const fechaActual = new Date(itemF.fecha);
                    const fechaDocumento = new Date(item.f_cargado);
                    const fnull = new Date(0);

                    let estado = "";
                    let color = "";

                    if (
                      fechaDocumento.toISOString() === fechaActual.toISOString()
                    ) {
                      estado = "ENTREGADO";
                      color = "#2E8B57";
                    } else if (fechaDocumento > fechaActual) {
                      estado = "RETRASO";
                      color = "#CD5C5C";
                    } else if (
                      fechaDocumento.toISOString() === fnull.toISOString()
                    ) {
                      estado = "PENDIENTE";
                      color = "#db922e";
                    } else {
                      estado = "ENTREGADO";
                      color = "#2E8B57";
                    }

                    return (
                      <tr className="text-center" key={item.id_doc}>
                        <td>{n1 + 1}</td>
                        <td>{item.responsable}</td>
                        <td>{item.nom_doc}</td>
                        <td>{item.f_cargado}</td>
                        <td
                          align="center"
                          style={{ color: "white", backgroundColor: color }}>
                          {estado}
                        </td>
                      </tr>
                    );
                  })}
                </>
              ))}
            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            size="lg"
            onClick={() => abrirCerrarModalVerInf()}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>

      {/* Modal VER REPORTE CUENTAS BECARIOS*/}
      <Modal isOpen={modalVerNot} size="lg">
        <ModalHeader className="header_mo" toggle={toggle} close={closeBtn2}>
          Estado Académico Becarios
        </ModalHeader>
        <ModalBody>
          <Button
            color="primary"
            size="lg"
            onClick={() => generatePDF(selectedUser)}>
            <FaIcons.FaFileDownload /> Reporte
          </Button>
          <hr></hr>
          <Input
            className="form-control"
            type="select"
            onChange={handleSelectUser}>
            <option value="">Seleccione becario</option>
            {uniqueUsers
              .sort((a, b) => a.nombre.localeCompare(b.nombre)) // Ordena por nombre
              .map((usuario, index) => (
                <option key={usuario.id_bec} value={usuario.id_bec}>
                  {index + 1}. {usuario.nombre} {usuario.apellido}
                </option>
              ))}
          </Input>
          <Table id="tabl">
            <thead id="stahead">
              <tr className="text-center">
                <th>N°</th>
                <th>Nombre Becario</th>
                <th>N. Académica</th>
                <th>Gestión</th>
                <th>Semestre</th>
                <th>N. Evaluación</th>
              </tr>
            </thead>
            <tbody>
              {dataS.reduce((acc, iteb, nb) => {
                // Verificar si el semestre no es vacío o null
                if (iteb.semestre && iteb.semestre !== "null") {
                  acc.push(
                    <tr key={iteb.id_bec + nb} className="text-center">
                      <td>{acc.length + 1}</td>{" "}
                      {/* Contador que inicia desde 1 */}
                      <td>
                        {iteb.nombre} {iteb.apellido}
                      </td>
                      <td>{iteb.promedio_semestre}</td>
                      <td>{iteb.anio}</td>
                      <td>{iteb.semestre}</td>
                      <td>{iteb.nota_eva}</td>
                    </tr>
                  );
                }
                return acc;
              }, [])}
            </tbody>
          </Table>
          <Card body>
            <CardTitle tag="h6">Estadísticas</CardTitle>
            <CardText
              className="text-center"
              style={{ color: "rgb(33 33 185)" }}>
              <b>
                Becario: {selectedUser ? selectedUser : "Seleccione un becario"}
              </b>
            </CardText>
            <div style={{ width: "100%", height: "400px" }} ref={chartRef}>
              <Line data={datosGraficoN} options={opcionesGraficoN} />
            </div>
          </Card>
        </ModalBody>
      </Modal>

      {/* Modal proyección*/}
      <Modal isOpen={modalProy} size="lg">
        <ModalHeader className="header_mo">
          Proyección poblacional de becarios
        </ModalHeader>
        <ModalBody>
          <Card body>
            <CardText
              className="text-center"
              style={{ color: "rgb(33 33 185)" }}>
              <b>Becarios</b>
            </CardText>
            <div style={{ width: "100%", height: "400px" }}>
              <Line data={datosGrafico} options={opcionesGrafico} />
            </div>
          </Card>
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            size="lg"
            onClick={() => abrirCerrarModalProy()}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Administrador;
