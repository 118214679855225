import React, { useState, useEffect } from "react";
import {
  FormGroup,
  Input,
  Button,
  Form,
  Row,
  Col,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  UncontrolledDropdown,
} from "reactstrap";
import "../bec.scss";
import axios from "axios";
import Swal from "sweetalert2";
import { NavLink } from "react-router-dom";

function Horarios4() {
  const [data, setData] = useState({
    id: "",
    id_B: "",
    mat_a1: "",
    hor_a1: "",
    hor_a2: "",
    hor_a3: "",
    dias_a1: "",
    mat_b1: "",
    hor_b1: "",
    hor_b2: "",
    hor_b3: "",
    dias_b1: "",
    mat_c1: "",
    hor_c1: "",
    hor_c2: "",
    hor_c3: "",
    dias_c1: "",
    mat_d1: "",
    hor_d1: "",
    hor_d2: "",
    hor_d3: "",
    dias_d1: "",
    mat_e1: "",
    hor_e1: "",
    hor_e2: "",
    hor_e3: "",
    dias_e1: "",
    mat_f1: "",
    hor_f1: "",
    hor_f2: "",
    hor_f3: "",
    dias_f1: "",
    mat_g1: "",
    hor_g1: "",
    hor_g2: "",
    hor_g3: "",
    dias_g1: "",
    semestre: "",
    anio: "",
    anio_carr: "",
  });
  // Definir un objeto de validación con patrones de expresiones regulares y estados de validación iniciales
  const validation = {
    anio_carr: { regex: /^\s*(\d{4,4})?\s*$/, isValid: true },
    anio: { regex: /^\d{4,4}$/, isValid: true },
    semestre: { regex: /^\d{1,2}-Semestre$/, isValid: true },
    mat_a1: { regex: /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s ]{0,35}$/, isValid: true },
    mat_b1: { regex: /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s ]{0,35}$/, isValid: true },
    mat_c1: { regex: /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s ]{0,35}$/, isValid: true },
    mat_d1: { regex: /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s ]{0,35}$/, isValid: true },
    mat_e1: { regex: /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s ]{0,35}$/, isValid: true },
    mat_f1: { regex: /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s ]{0,35}$/, isValid: true },
    mat_g1: { regex: /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s ]{0,35}$/, isValid: true },
    dias_a1: {
      regex:
        /^(?:(lunes|martes|miércoles|jueves|viernes|sábado|domingo)(, (lunes|martes|miércoles|jueves|viernes|sábado|domingo))*)?$/i,
      isValid: true,
    },
    dias_b1: {
      regex:
        /^(?:(lunes|martes|miércoles|jueves|viernes|sábado|domingo)(, (lunes|martes|miércoles|jueves|viernes|sábado|domingo))*)?$/i,
      isValid: true,
    },
    dias_c1: {
      regex:
        /^(?:(lunes|martes|miércoles|jueves|viernes|sábado|domingo)(, (lunes|martes|miércoles|jueves|viernes|sábado|domingo))*)?$/i,
      isValid: true,
    },
    dias_d1: {
      regex:
        /^(?:(lunes|martes|miércoles|jueves|viernes|sábado|domingo)(, (lunes|martes|miércoles|jueves|viernes|sábado|domingo))*)?$/i,
      isValid: true,
    },
    dias_e1: {
      regex:
        /^(?:(lunes|martes|miércoles|jueves|viernes|sábado|domingo)(, (lunes|martes|miércoles|jueves|viernes|sábado|domingo))*)?$/i,
      isValid: true,
    },
    dias_f1: {
      regex:
        /^(?:(lunes|martes|miércoles|jueves|viernes|sábado|domingo)(, (lunes|martes|miércoles|jueves|viernes|sábado|domingo))*)?$/i,
      isValid: true,
    },
    dias_g1: {
      regex:
        /^(?:(lunes|martes|miércoles|jueves|viernes|sábado|domingo)(, (lunes|martes|miércoles|jueves|viernes|sábado|domingo))*)?$/i,
      isValid: true,
    },
    hor_a1: {
      regex: /^([0-9]|0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/,
      isValid: true,
    },
    hor_a2: {
      regex: /^([0-9]|0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/,
      isValid: true,
    },
    hor_a3: {
      regex: /^([0-9]|0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/,
      isValid: true,
    },
    hor_b1: {
      regex: /^([0-9]|0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/,
      isValid: true,
    },
    hor_b2: {
      regex: /^([0-9]|0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/,
      isValid: true,
    },
    hor_b3: {
      regex: /^([0-9]|0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/,
      isValid: true,
    },
    hor_c1: {
      regex: /^([0-9]|0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/,
      isValid: true,
    },
    hor_c2: {
      regex: /^([0-9]|0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/,
      isValid: true,
    },
    hor_c3: {
      regex: /^([0-9]|0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/,
      isValid: true,
    },
    hor_d1: {
      regex: /^([0-9]|0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/,
      isValid: true,
    },
    hor_d2: {
      regex: /^([0-9]|0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/,
      isValid: true,
    },
    hor_d3: {
      regex: /^([0-9]|0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/,
      isValid: true,
    },
    hor_e1: {
      regex: /^([0-9]|0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/,
      isValid: true,
    },
    hor_e2: {
      regex: /^([0-9]|0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/,
      isValid: true,
    },
    hor_e3: {
      regex: /^([0-9]|0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/,
      isValid: true,
    },
    hor_f1: {
      regex: /^([0-9]|0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/,
      isValid: true,
    },
    hor_f2: {
      regex: /^([0-9]|0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/,
      isValid: true,
    },
    hor_f3: {
      regex: /^([0-9]|0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/,
      isValid: true,
    },
    hor_g1: {
      regex: /^([0-9]|0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/,
      isValid: true,
    },
    hor_g2: {
      regex: /^([0-9]|0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/,
      isValid: true,
    },
    hor_g3: {
      regex: /^([0-9]|0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/,
      isValid: true,
    },
  };

  const [isValid, setIsValid] = useState({
    anio_carr: true,
    semestre: true,
    anio: true,
    mat_a1: true,
    mat_b1: true,
    mat_c1: true,
    mat_d1: true,
    mat_e1: true,
    mat_f1: true,
    mat_g1: true,
    dias_a1: true,
    dias_b1: true,
    dias_c1: true,
    dias_d1: true,
    dias_e1: true,
    dias_f1: true,
    dias_g1: true,
    hor_a1: true,
    hor_a2: true,
    hor_a3: true,
    hor_b1: true,
    hor_b2: true,
    hor_b3: true,
    hor_c1: true,
    hor_c2: true,
    hor_c3: true,
    hor_d1: true,
    hor_d2: true,
    hor_d3: true,
    hor_e1: true,
    hor_e2: true,
    hor_e3: true,
    hor_f1: true,
    hor_f2: true,
    hor_f3: true,
    hor_g1: true,
    hor_g2: true,
    hor_g3: true,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevState) => ({ ...prevState, [name]: value }));

    const isValidValue = validation[name].regex.test(value);
    setIsValid((prevState) => ({ ...prevState, [name]: isValidValue }));
  };
  ///Listar horario 4
  const peticionGet = async () => {
    await axios
      .get(`https://bqef-bo.com/api/B/hor4.php`, {
        params: {
          id: sessionStorage.getItem("iduser"),
        },
      })
      .then((response) => {
        // // ////console.log(response.data);;
        setData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  ////Modificar horario 4
  const peticionPut = async () => {
    var f = new FormData();
    ///)
    f.append("semestre", data.semestre);
    f.append("anio", data.anio);
    f.append("anio_carr", data.anio_carr);
    f.append("mat_a1", data.mat_a1);
    f.append("hor_a1", data.hor_a1);
    f.append("hor_a2", data.hor_a2);
    f.append("hor_a3", data.hor_a3);
    f.append("dias_a1", data.dias_a1);
    f.append("mat_b1", data.mat_b1);
    f.append("hor_b1", data.hor_b1);
    f.append("hor_b2", data.hor_b2);
    f.append("hor_b3", data.hor_b3);
    f.append("dias_b1", data.dias_b1);
    f.append("mat_c1", data.mat_c1);
    f.append("hor_c1", data.hor_c1);
    f.append("hor_c2", data.hor_c2);
    f.append("hor_c3", data.hor_c3);
    f.append("dias_c1", data.dias_c1);
    f.append("mat_d1", data.mat_d1);
    f.append("hor_d1", data.hor_d1);
    f.append("hor_d2", data.hor_d2);
    f.append("hor_d3", data.hor_d3);
    f.append("dias_d1", data.dias_d1);
    f.append("mat_e1", data.mat_e1);
    f.append("hor_e1", data.hor_e1);
    f.append("hor_e2", data.hor_e2);
    f.append("hor_e3", data.hor_e3);
    f.append("dias_e1", data.dias_e1);
    f.append("mat_f1", data.mat_f1);
    f.append("hor_f1", data.hor_f1);
    f.append("hor_f2", data.hor_f2);
    f.append("hor_f3", data.hor_f3);
    f.append("dias_f1", data.dias_f1);
    f.append("mat_g1", data.mat_g1);
    f.append("hor_g1", data.hor_g1);
    f.append("hor_g2", data.hor_g2);
    f.append("hor_g3", data.hor_g3);
    f.append("dias_g1", data.dias_g1);
    ///
    f.append("METHOD", "PUT");
    await axios
      .post(`https://bqef-bo.com/api/B/hor4.php`, f, {
        params: {
          id: sessionStorage.getItem("iduser"),
        },
      })
      .then((response) => {
        setData(response);
        peticionGet();
        mostrarAlertaU();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    peticionGet();
  }, []);
  ////ALERTAS
  const mostrarAlertaU = () => {
    Swal.fire({
      confirmButtonColor: "#2E8B57",
      title: "Éxito!",
      text: "Registro Actualizado.",
      timer: 5000,
      icon: "success",
    });
  };
  ///Paginación
  const menuItem = [
    {
      path: "../horarios1",
      name: "Primer Semestre",
    },
    {
      path: "../horarios2",
      name: "Segundo Semestre",
    },
    {
      path: "../horarios3",
      name: "Tercer Semestre",
    },
    {
      path: "../horarios4",
      name: "Cuarto Semestre",
    },
    {
      path: "../horarios5",
      name: "Quinto Semestre",
    },
    {
      path: "../horarios6",
      name: "Sexto Semestre",
    },
    {
      path: "../horarios7",
      name: "Séptimo Semestre",
    },
    {
      path: "../horarios8",
      name: "Octavo Semestre",
    },
    {
      path: "../horarios9",
      name: "Noveno Semestre",
    },
    {
      path: "../horarios10",
      name: "Décimo Semestre",
    },
  ];
  return (
    <div id="main_content">
      <div className="tra">
        <div className="tra title-form">
          <h2 className>Horario 4</h2>
        </div>
        <div className="frm">
          <span>
            <b>Instrucciones:</b> - Llena el formulario con tu horario de clases
            actual y sus debidos horarios.
            <br />- Tomar en cuenta el formato de la hora (AM/PM).
          </span>
        </div>

        <Form className="frm">
          <Row>
            <UncontrolledDropdown direction="down">
              <DropdownToggle caret color="danger">
                SEMESTRES
              </DropdownToggle>
              <DropdownMenu>
                {menuItem.map((item, index) => (
                  <DropdownItem key={index}>
                    <NavLink to={item.path} exact activeClassName="active">
                      <div className="link_text">{item.name}</div>
                    </NavLink>
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          </Row>
          <hr></hr>
          <Row>
            <Col md={3}>
              <h5>Seleccionar Año o Semestre Correspondiente:</h5>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Input
                  name="anio_carr"
                  type="text"
                  placeholder="Año"
                  onChange={handleChange}
                  value={data.anio_carr}
                />
                {!isValid.anio_carr && (
                  <span style={{ color: "red" }}>Formato año no válido</span>
                )}
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Input
                  name="semestre"
                  type="text"
                  placeholder="1-Semestre"
                  onChange={handleChange}
                  value={data.semestre}
                />
                {!isValid.semestre && (
                  <span style={{ color: "red" }}>
                    Formato semestre no válido
                  </span>
                )}
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Input
                  name="anio"
                  type="text"
                  onChange={handleChange}
                  value={data.anio}
                />
                {!isValid.anio && (
                  <span style={{ color: "red" }}>Formato año no válido</span>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row className="text-center">
            <Col md={3}>
              <h5>Materias</h5>
            </Col>
            <Col md={3}>
              <h5>Días</h5>
            </Col>
            <Col colSpan={3}>
              <h5>Horas</h5>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Input
                name="mat_a1"
                type="text"
                placeholder="Materia: 1"
                onChange={handleChange}
                value={data.mat_a1}
              />
              {!isValid.mat_a1 && (
                <span style={{ color: "red" }}>
                  El texto debe tener como máximo 35 letras y espacios
                </span>
              )}
            </Col>
            <Col md={3}>
              <Input
                name="dias_a1"
                type="text"
                placeholder="Lunes, Martes, Miércoles, Jueves, Viernes"
                onChange={handleChange}
                value={data.dias_a1}
              />
              {!isValid.dias_a1 && (
                <span style={{ color: "red" }}>
                  El texto debe tener como máximo 35 letras y espacios
                </span>
              )}
            </Col>
            <Col>
              <Input
                name="hor_a1"
                type="time"
                onChange={handleChange}
                value={data.hor_a1}
              />
              {!isValid.hor_a1 && (
                <span style={{ color: "red" }}>
                  El campo debe tener el formato establecido
                </span>
              )}
            </Col>
            <Col>
              <Input
                name="hor_a2"
                type="time"
                onChange={handleChange}
                value={data.hor_a2}
              />
              {!isValid.hor_a2 && (
                <span style={{ color: "red" }}>
                  El campo debe tener el formato establecido
                </span>
              )}
            </Col>
            <Col>
              <Input
                name="hor_a3"
                type="time"
                onChange={handleChange}
                value={data.hor_a3}
              />
              {!isValid.hor_a3 && (
                <span style={{ color: "red" }}>
                  El campo debe tener el formato establecido
                </span>
              )}
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Input
                name="mat_b1"
                type="text"
                placeholder="Materia: 2"
                onChange={handleChange}
                value={data.mat_b1}
              />
              {!isValid.mat_b1 && (
                <span style={{ color: "red" }}>
                  El texto debe tener como máximo 35 letras y espacios
                </span>
              )}
            </Col>
            <Col md={3}>
              <Input
                name="dias_b1"
                type="text"
                placeholder="Lunes, Martes, Miércoles, Jueves, Viernes"
                onChange={handleChange}
                value={data.dias_b1}
              />
              {!isValid.dias_b1 && (
                <span style={{ color: "red" }}>
                  El texto debe tener como máximo 35 letras y espacios
                </span>
              )}
            </Col>
            <Col>
              <Input
                name="hor_b1"
                type="time"
                onChange={handleChange}
                value={data.hor_b1}
              />
              {!isValid.hor_b1 && (
                <span style={{ color: "red" }}>
                  El campo debe tener el formato establecido
                </span>
              )}
            </Col>
            <Col>
              <Input
                name="hor_b2"
                type="time"
                onChange={handleChange}
                value={data.hor_b2}
              />
              {!isValid.hor_b2 && (
                <span style={{ color: "red" }}>
                  El campo debe tener el formato establecido
                </span>
              )}
            </Col>
            <Col>
              <Input
                name="hor_b3"
                type="time"
                onChange={handleChange}
                value={data.hor_b3}
              />
              {!isValid.hor_b3 && (
                <span style={{ color: "red" }}>
                  El campo debe tener el formato establecido
                </span>
              )}
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Input
                name="mat_c1"
                type="text"
                placeholder="Materia: 3"
                onChange={handleChange}
                value={data.mat_c1}
              />
              {!isValid.mat_c1 && (
                <span style={{ color: "red" }}>
                  El texto debe tener como máximo 35 letras y espacios
                </span>
              )}
            </Col>
            <Col md={3}>
              <Input
                name="dias_c1"
                type="text"
                placeholder="Lunes, Martes, Miércoles, Jueves, Viernes"
                onChange={handleChange}
                value={data.dias_c1}
              />
              {!isValid.dias_c1 && (
                <span style={{ color: "red" }}>
                  El texto debe tener como máximo 35 letras y espacios
                </span>
              )}
            </Col>
            <Col>
              <Input
                name="hor_c1"
                type="time"
                onChange={handleChange}
                value={data.hor_c1}
              />
              {!isValid.hor_c1 && (
                <span style={{ color: "red" }}>
                  El campo debe tener el formato establecido
                </span>
              )}
            </Col>
            <Col>
              <Input
                name="hor_c2"
                type="time"
                onChange={handleChange}
                value={data.hor_c2}
              />
              {!isValid.hor_c2 && (
                <span style={{ color: "red" }}>
                  El campo debe tener el formato establecido
                </span>
              )}
            </Col>
            <Col>
              <Input
                name="hor_c3"
                type="time"
                onChange={handleChange}
                value={data.hor_c3}
              />
              {!isValid.hor_c3 && (
                <span style={{ color: "red" }}>
                  El campo debe tener el formato establecido
                </span>
              )}
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Input
                name="mat_d1"
                type="text"
                placeholder="Materia: 4"
                onChange={handleChange}
                value={data.mat_d1}
              />
              {!isValid.mat_d1 && (
                <span style={{ color: "red" }}>
                  El texto debe tener como máximo 35 letras y espacios
                </span>
              )}
            </Col>
            <Col md={3}>
              <Input
                name="dias_d1"
                type="text"
                placeholder="Lunes, Martes, Miércoles, Jueves, Viernes"
                onChange={handleChange}
                value={data.dias_d1}
              />
              {!isValid.dias_d1 && (
                <span style={{ color: "red" }}>
                  El texto debe tener como máximo 35 letras y espacios
                </span>
              )}
            </Col>
            <Col>
              <Input
                name="hor_d1"
                type="time"
                onChange={handleChange}
                value={data.hor_d1}
              />
              {!isValid.hor_d1 && (
                <span style={{ color: "red" }}>
                  El campo debe tener el formato establecido
                </span>
              )}
            </Col>
            <Col>
              <Input
                name="hor_d2"
                type="time"
                onChange={handleChange}
                value={data.hor_d2}
              />
              {!isValid.hor_d2 && (
                <span style={{ color: "red" }}>
                  El campo debe tener el formato establecido
                </span>
              )}
            </Col>
            <Col>
              <Input
                name="hor_d3"
                type="time"
                onChange={handleChange}
                value={data.hor_d3}
              />
              {!isValid.hor_d3 && (
                <span style={{ color: "red" }}>
                  El campo debe tener el formato establecido
                </span>
              )}
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Input
                name="mat_e1"
                type="text"
                placeholder="Materia: 5"
                onChange={handleChange}
                value={data.mat_e1}
              />
              {!isValid.mat_e1 && (
                <span style={{ color: "red" }}>
                  El texto debe tener como máximo 35 letras y espacios
                </span>
              )}
            </Col>
            <Col md={3}>
              <Input
                name="dias_e1"
                type="text"
                placeholder="Lunes, Martes, Miércoles, Jueves, Viernes"
                onChange={handleChange}
                value={data.dias_e1}
              />
              {!isValid.dias_e1 && (
                <span style={{ color: "red" }}>
                  El texto debe tener como máximo 35 letras y espacios
                </span>
              )}
            </Col>
            <Col>
              <Input
                name="hor_e1"
                type="time"
                onChange={handleChange}
                value={data.hor_e1}
              />
              {!isValid.hor_e1 && (
                <span style={{ color: "red" }}>
                  El campo debe tener el formato establecido
                </span>
              )}
            </Col>
            <Col>
              <Input
                name="hor_e2"
                type="time"
                onChange={handleChange}
                value={data.hor_e2}
              />
              {!isValid.hor_e2 && (
                <span style={{ color: "red" }}>
                  El campo debe tener el formato establecido
                </span>
              )}
            </Col>
            <Col>
              <Input
                name="hor_e3"
                type="time"
                onChange={handleChange}
                value={data.hor_e3}
              />
              {!isValid.hor_e3 && (
                <span style={{ color: "red" }}>
                  El campo debe tener el formato establecido
                </span>
              )}
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Input
                name="mat_f1"
                type="text"
                placeholder="Materia: 6"
                onChange={handleChange}
                value={data.mat_f1}
              />
              {!isValid.mat_f1 && (
                <span style={{ color: "red" }}>
                  El texto debe tener como máximo 35 letras y espacios
                </span>
              )}
            </Col>
            <Col md={3}>
              <Input
                name="dias_f1"
                type="text"
                placeholder="Lunes, Martes, Miércoles, Jueves, Viernes"
                onChange={handleChange}
                value={data.dias_f1}
              />
              {!isValid.dias_f1 && (
                <span style={{ color: "red" }}>
                  El texto debe tener como máximo 35 letras y espacios
                </span>
              )}
            </Col>
            <Col>
              <Input
                name="hor_f1"
                type="time"
                onChange={handleChange}
                value={data.hor_f1}
              />
              {!isValid.hor_f1 && (
                <span style={{ color: "red" }}>
                  El campo debe tener el formato establecido
                </span>
              )}
            </Col>
            <Col>
              <Input
                name="hor_f2"
                type="time"
                onChange={handleChange}
                value={data.hor_f2}
              />
              {!isValid.hor_f2 && (
                <span style={{ color: "red" }}>
                  El campo debe tener el formato establecido
                </span>
              )}
            </Col>
            <Col>
              <Input
                name="hor_f3"
                type="time"
                onChange={handleChange}
                value={data.hor_f3}
              />
              {!isValid.hor_f3 && (
                <span style={{ color: "red" }}>
                  El campo debe tener el formato establecido
                </span>
              )}
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Input
                name="mat_g1"
                type="text"
                placeholder="Materia: 7"
                onChange={handleChange}
                value={data.mat_g1}
              />
              {!isValid.mat_g1 && (
                <span style={{ color: "red" }}>
                  El texto debe tener como máximo 35 letras y espacios
                </span>
              )}
            </Col>
            <Col md={3}>
              <Input
                name="dias_g1"
                type="text"
                placeholder="Lunes, Martes, Miércoles, Jueves, Viernes"
                onChange={handleChange}
                value={data.dias_g1}
              />
              {!isValid.dias_g1 && (
                <span style={{ color: "red" }}>
                  El texto debe tener como máximo 35 letras y espacios
                </span>
              )}
            </Col>
            <Col>
              <Input
                name="hor_g1"
                type="time"
                onChange={handleChange}
                value={data.hor_g1}
              />
              {!isValid.hor_g1 && (
                <span style={{ color: "red" }}>
                  El campo debe tener el formato establecido
                </span>
              )}
            </Col>
            <Col>
              <Input
                name="hor_g2"
                type="time"
                onChange={handleChange}
                value={data.hor_g2}
              />
              {!isValid.hor_g2 && (
                <span style={{ color: "red" }}>
                  El campo debe tener el formato establecido
                </span>
              )}
            </Col>
            <Col>
              <Input
                name="hor_g3"
                type="time"
                onChange={handleChange}
                value={data.hor_g3}
              />
              {!isValid.hor_g3 && (
                <span style={{ color: "red" }}>
                  El campo debe tener el formato establecido
                </span>
              )}
            </Col>
          </Row>
          <div id="fotb">
            <Button
              color="success"
              size="lg"
              onClick={() => peticionPut()}
              disabled={
                !isValid.anio ||
                !isValid.anio_carr ||
                !isValid.semestre ||
                !isValid.mat_a1 ||
                !isValid.mat_b1 ||
                !isValid.mat_c1 ||
                !isValid.mat_d1 ||
                !isValid.mat_e1 ||
                !isValid.mat_f1 ||
                !isValid.mat_g1 ||
                !isValid.dias_a1 ||
                !isValid.dias_b1 ||
                !isValid.dias_c1 ||
                !isValid.dias_d1 ||
                !isValid.dias_e1 ||
                !isValid.dias_f1 ||
                !isValid.dias_g1 ||
                !isValid.hor_a1 ||
                !isValid.hor_a2 ||
                !isValid.hor_a3 ||
                !isValid.hor_b1 ||
                !isValid.hor_b2 ||
                !isValid.hor_b3 ||
                !isValid.hor_c1 ||
                !isValid.hor_c2 ||
                !isValid.hor_c3 ||
                !isValid.hor_d1 ||
                !isValid.hor_d2 ||
                !isValid.hor_d3 ||
                !isValid.hor_e1 ||
                !isValid.hor_e2 ||
                !isValid.hor_e3 ||
                !isValid.hor_f1 ||
                !isValid.hor_f2 ||
                !isValid.hor_f3 ||
                !isValid.hor_g1 ||
                !isValid.hor_g2 ||
                !isValid.hor_g3
              }>
              Modificar
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default Horarios4;
