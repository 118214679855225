import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  Button,
  Label,
  Input,
  Row,
} from "reactstrap";
import * as FaIcons from "react-icons/fa";
import axios from "axios";
import Swal from "sweetalert2";
import jsPDF from "jspdf";
import logos from "../../components/img/base/111.png";

const CargaDoc = () => {
  const abrirCerrarModalInsertar = () => {
    setModalInsertar(!modalInsertar);
  };
  /////Listar datos personal administrativo
  const [dataPer, setDataPer] = useState({});
  const peticionGet = async () => {
    await axios
      .get(`https://bqef-bo.com/api/P/contraper.php`, {
        params: {
          id: sessionStorage.getItem("iduser"),
        },
      })
      .then((response) => {
        setDataPer(response.data);
        //console.log(response.data);;
      })
      .catch((error) => {
        console.log(error);
      });
  };
  //modal ver
  const [modalVer, setModalVer] = useState(false);
  const abrirCerrarModalVer = () => {
    setModalVer(!modalVer);
  };
  const [usuarioSeleccionado, setUsuarioSeleccionado] = useState({
    id_com: "",
    nom_doc: "",
    id_per: "",
    detalle: "",
    archivo_com: null,
  });

  // Definir un objeto de validación con patrones de expresiones regulares y estados de validación iniciales
  const validation = {
    nom_doc: { regex: /^[a-zA-Z\s]{1,20}$/, isValid: true },
    detalle: { regex: /^[a-zA-Z\s]{1,50}$/, isValid: true },
    archivo_com: { isValid: false },
  };

  const [isValid, setIsValid] = useState({
    nom_doc: true,
    detalle: true,
    archivo_com: false,
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    // Manejar cambio de texto
    setUsuarioSeleccionado((prevState) => ({ ...prevState, [name]: value }));
    if (validation[name] && validation[name].regex) {
      const isValidValue = validation[name].regex.test(value);
      setIsValid((prevState) => ({ ...prevState, [name]: isValidValue }));
    }
  };

  ////Mostrar comunicados
  const baseUrl = "https://bqef-bo.com/api/P/comCP.php";
  const [lista, setLista] = useState([]);
  async function getComunicado() {
    const res = await axios.get(baseUrl);
    setLista(res.data);
    // console.log(res.data);
    setTablaUsuarios(res.data);
  }
  ////Agregar comunicado
  const [comunicado, setComunicado] = useState(null);
  async function addComunicado(e) {
    e.preventDefault();
    let fd = new FormData();
    fd.append("archivo_com", comunicado);
    fd.append("detalle", usuarioSeleccionado.detalle);
    fd.append("nom_doc", usuarioSeleccionado.nom_doc);
    fd.append("nom_usu", dataPer.nombre + " " + dataPer.apellido);
    const res = await axios.post(baseUrl, fd);
    console.log(res.data);
    mostrarAlertaN();
    abrirCerrarModalInsertar();
    getComunicado();
  }
  const seleccionarUsuario = (Usuario, caso) => {
    setUsuarioSeleccionado(Usuario);

    caso === "Editar" ? abrirCerrarModalVer() : abrirCerrarModalEliminar();
  };
  ////eliminar comunicado
  async function deleteComunicado(id_com) {
    const res = await axios.delete(
      "https://bqef-bo.com/api/P/comCP.php?id_com=" + id_com
    );
    abrirCerrarModalEliminar();
    getComunicado();
    mostrarAlertaD();
    console.log(res.data);
  }
  ////
  const [modalInsertar, setModalInsertar] = useState(false);
  //modal eliminar
  const [modalEliminar, setModalEliminar] = useState(false);
  const abrirCerrarModalEliminar = () => {
    setModalEliminar(!modalEliminar);
  };
  ////PDF
  const handleDescargarPdf = async () => {
    var link = document.createElement("a");
    // Se agregan los prefijos de href para indicar que el contenido que sigue está en formato PDF y
    // está codificado en Base64.
    link.setAttribute(
      "href",
      "data:application/pdf;base64," + usuarioSeleccionado.archivo_com
    );
    link.setAttribute("download", usuarioSeleccionado.nom_doc);
    link.click();
  };
  ////
  const closeBtn = (
    <Button className="close" onClick={() => abrirCerrarModalVer()}>
      &times;
    </Button>
  );
  useEffect(() => {
    getComunicado();
    peticionGet();
  }, []);
  ////BARRA BUSQUEDA
  const [busqueda, setBusqueda] = useState("");
  const [tablaUsuarios, setTablaUsuarios] = useState([]);
  // const [usuarios, setUsuarios] = useState([]);

  const handleChangeB = (e) => {
    setBusqueda(e.target.value);
    filtrar(e.target.value);
  };

  const filtrar = (terminoBusqueda) => {
    const resultadosBusqueda = tablaUsuarios.filter((elemento) => {
      const nom_docMatch = elemento.nom_doc
        .toString()
        .toLowerCase()
        .includes(terminoBusqueda.toLowerCase());
      const detalleMatch = elemento.detalle
        .toString()
        .toLowerCase()
        .includes(terminoBusqueda.toLowerCase());
      const id_perMatch = elemento.id_per
        .toString()
        .toLowerCase()
        .includes(terminoBusqueda.toLowerCase());

      return nom_docMatch || detalleMatch || id_perMatch;
    });

    setLista(resultadosBusqueda);
  };
  var cont = 1;
  ////ALERTAS
  const mostrarAlertaN = () => {
    Swal.fire({
      confirmButtonColor: "#2E8B57",
      title: "Éxito!",
      text: "Registro Guardado.",
      timer: 5000,
      icon: "success",
    });
  };
  const mostrarAlertaD = () => {
    Swal.fire({
      confirmButtonColor: "#2E8B57",
      title: "Eliminado!",
      text: "Registro Eliminado.",
      timer: 5000,
      icon: "success",
    });
  };
  ///GENERAR PDF INFORME
  const generatePDF = () => {
    // Crear un nuevo documento PDF en orientación vertical
    const doc = new jsPDF();

    // Agregar logo al documento PDF
    doc.addImage(logos, "PNG", 10, 10, 20, 10);

    // Establecer la fuente en negrita
    doc.setFont("helvetica", "bold");

    // Agregar título "FONDO DE EDUCACIÓN" al documento PDF
    doc.setFontSize(12);
    const title = "FONDO DE EDUCACION DE CUAQUEROS BOLIVIANOS";
    const titleWidth = doc.getTextWidth(title);
    const x = (doc.internal.pageSize.width - titleWidth) / 2; // Calcular posición X para centrar
    const y = 20; // Posición Y del título

    // Escribir el título
    doc.text(title, x, y);

    // Dibujar la línea subrayando el título
    doc.line(x, y + 1, x + titleWidth, y + 1); // Dibuja la línea

    // Restablecer la fuente a normal
    doc.setFont("helvetica", "normal");

    // Agregar subtítulo "Reporte" al documento PDF
    const subtitle = "Reporte Carga Comunicado";
    const subtitleWidth = doc.getTextWidth(subtitle);
    const subtitleX = (doc.internal.pageSize.width - subtitleWidth) / 2; // Calcular posición X para centrar
    doc.text(subtitle, subtitleX, 30);

    // Agregar fecha al documento PDF
    const fecha = new Date();
    const dia = fecha.getDate();
    const mes = fecha.getMonth() + 1;
    const año = fecha.getFullYear();
    doc.setFontSize(9);
    doc.text(`Fecha: ${dia}/${mes}/${año}`, 10, 25);

    // Establecer la fuente en negrita
    doc.setFont("helvetica", "bold");

    // Definir el texto "Usuario: "
    const usuarioText = "Usuario: Personal Administrativo";

    // Calcular el ancho del texto
    const usuarioWidth = doc.getTextWidth(usuarioText);

    // Calcular la posición X para alinearlo a la derecha
    const usuarioX = doc.internal.pageSize.width - usuarioWidth - 10; // 10 mm de margen desde el borde derecho

    // Escribir el texto "Usuario:" en la posición calculada
    doc.text(usuarioText, usuarioX, 35);

    // Definir los datos de la tabla
    const userData = lista
      .map((iteb) => ({
        nom_doc: `${iteb.nom_doc}`,
        id_per: iteb.id_per,
        detalle: iteb.detalle,
      }))
      .sort((a, b) => a.nom_doc.localeCompare(b.nom_doc)) // Ordenar por nombre en orden alfabético ascendente
      .map((iteb, nb) => ({
        it: nb + 1, // Asignar el índice de forma ascendente después de ordenar
        ...iteb, // Desestructurar el resto de las propiedades
      }));

    // Definir las columnas de la tabla
    const columns = [
      { header: "N°", dataKey: "it" },
      { header: "Nombre Documento", dataKey: "nom_doc" },
      { header: "ID Usuario", dataKey: "id_per" },
      { header: "Detalle", dataKey: "detalle" },
    ];

    // Agregar la tabla al documento PDF
    doc.autoTable(columns, userData, {
      startY: 40,
      margin: { top: 20, bottom: 20 },
      bodyStyles: { fontSize: 8 },
      headStyles: { fontSize: 9, fillColor: [10, 49, 67] },
    });

    // Agregar número de página al documento PDF
    let totalPages = doc.internal.getNumberOfPages();
    for (let i = 1; i <= totalPages; i++) {
      doc.setPage(i);
      doc.setFontSize(9);
      doc.text(
        `Página ${i} de ${totalPages}`,
        10,
        doc.internal.pageSize.height - 10
      );
    }

    // Convertir el documento PDF a blob
    const pdfBlob = doc.output("blob");

    // Abrir el PDF en una nueva ventana
    const url = URL.createObjectURL(pdfBlob);
    window.open(url, "_blank");
  };
  return (
    <div id="main_content">
      <div className="tra">
        <div className="tra title-form">
          <h2 className>Gestion Comunicados</h2>
        </div>
        <div className="frm">
          <Button color="primary" size="lg" onClick={generatePDF}>
            <FaIcons.FaFileDownload /> Reporte
          </Button>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Button
            color="success"
            size="lg"
            onClick={() => abrirCerrarModalInsertar()}>
            <FaIcons.FaPlus /> Añadir
          </Button>
        </div>
        <div className="frm containerInput">
          <Input
            className="form-control inputBuscar"
            size="lg"
            value={busqueda}
            placeholder="Búsqueda por Nombre o ID Usuario"
            onChange={handleChangeB}
          />
          <Button className="btn btn-success" size="lg">
            <FaIcons.FaSearch /> Buscar
          </Button>
          <br />
        </div>

        <Table className="frm" responsive="sm" id="tabl">
          <thead>
            <tr className="text-center tra title-form">
              <th>#</th>
              <th>Nombre documento</th>
              <th>ID Usuario</th>
              <th>Detalle</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {lista.map((item) => (
              <tr className="text-center" key={item.id_com}>
                <td>{cont++}</td>
                <td>{item.nom_doc}</td>
                <td>{item.id_per}</td>
                <td>{item.detalle}</td>
                <td>
                  <Button
                    color="warning"
                    onClick={() => seleccionarUsuario(item, "Editar")}>
                    <FaIcons.FaRegEye />
                    &nbsp;&nbsp;Visualizar
                  </Button>
                  &nbsp;&nbsp;&nbsp;
                  <Button
                    color="danger"
                    onClick={() => seleccionarUsuario(item, "Eliminar")}>
                    Eliminar
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {/* Modal agregar comunicado */}
        <Modal isOpen={modalInsertar}>
          <ModalHeader className="header_m">Cargar documento</ModalHeader>
          <ModalBody>
            <div className="form-group">
              <Label>Nombre documento: </Label>
              <br />
              <Input
                type="text"
                className="form-control"
                name="nom_doc"
                value={usuarioSeleccionado.nom_doc}
                onChange={handleChange}
              />
              {!isValid.nom_doc && (
                <span style={{ color: "red" }}>
                  El texto debe tener como máximo 20 letras y espacios
                </span>
              )}
              <br />
              <Label>Usuario: </Label>
              <br />
              <Input
                disabled
                type="text"
                className="form-control"
                name="nom_usu"
                value={dataPer.nombre + " " + dataPer.apellido}
                // onChange={(e) => setNomusu(e.target.value)}
              />
              <br />
              <Label>Informe personal: </Label>
              <br />
              <Input
                type="file"
                className="form-control"
                accept="archivo_com/*"
                onChange={(e) => setComunicado(e.target.files[0])}
                multiple
              />
              <br />
              <Label>Detalle: </Label>
              <br />
              <textarea
                type="text"
                className="form-control"
                name="detalle"
                value={usuarioSeleccionado.detalle}
                onChange={handleChange}
              />
              {!isValid.detalle && (
                <span style={{ color: "red" }}>
                  El texto debe tener como máximo 50 letras y espacios
                </span>
              )}
              <br />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="success"
              size="lg"
              onClick={(e) => addComunicado(e)}
              disabled={!isValid.nom_doc || !isValid.detalle}>
              Guardar
            </Button>
            <Button
              color="danger"
              size="lg"
              onClick={() => abrirCerrarModalInsertar()}>
              Cancelar
            </Button>
          </ModalFooter>
        </Modal>
        {/* Modal Eliminar */}
        <Modal isOpen={modalEliminar}>
          <ModalHeader className="header_m">Eliminar comunicado</ModalHeader>
          <ModalBody>
            ¿Estás seguro que deseas eliminar el documento{" "}
            {usuarioSeleccionado && usuarioSeleccionado.nom_doc}?
          </ModalBody>
          <ModalFooter>
            <Button
              color="success"
              size="lg"
              onClick={() => deleteComunicado(usuarioSeleccionado.id_com)}>
              Sí
            </Button>
            <Button
              color="danger"
              size="lg"
              onClick={() => abrirCerrarModalEliminar()}>
              No
            </Button>
          </ModalFooter>
        </Modal>

        {/* Modal VER */}
        <Modal isOpen={modalVer} size="xl">
          <ModalHeader className="header_m" close={closeBtn}>
            {usuarioSeleccionado && usuarioSeleccionado.nom_doc}
          </ModalHeader>
          <ModalBody>
            <Card>
              <CardBody className="p-0">
                <Row className="justify-content-center">
                  <object
                    data={
                      "data:application/pdf;base64," +
                      usuarioSeleccionado.archivo_com
                    }
                    type="application/pdf"
                    alt="archivo_per"
                    width="400"
                    height="600">
                    <p>
                      Tu navegador no puede mostrar este archivo PDF. Puedes
                      descargarlo
                      <a
                        href={
                          "data:application/pdf;base64," +
                          usuarioSeleccionado.archivo_com
                        }
                        download>
                        aquí
                      </a>
                      .
                    </p>
                  </object>
                </Row>
              </CardBody>
            </Card>
          </ModalBody>
          <ModalFooter>
            <Button
              style={{ float: "right" }}
              color="success"
              size="lg"
              onClick={handleDescargarPdf}>
              <FaIcons.FaDownload />
              Download
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
};

export default CargaDoc;
