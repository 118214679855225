import React, { useState, useEffect } from "react";
import "../navbar.scss";
import Logo from "../img/base/111.png";
import axios from "axios";
import * as FaIcons from "react-icons/fa";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavbarText,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  CardImg,
  Input,
  Label,
  Row,
  Card,
  CardBody,
  Alert,
} from "reactstrap";
import { Outlet, useNavigate, Link } from "react-router-dom";
import SidebarBec from "./SidebarBec";
import Swal from "sweetalert2";
import { useAuth } from "../../utils/AuthContext";
import useIdleTimer from "../../utils/useIdleTimer";

const TIMEOUT_DURATION = 5 * 60 * 1000; // 5 minutos en milisegundos

function NavbarBec(args) {
  const [modalContra, setModalContra] = useState(false);
  const abrirCerrarModalContra = () => {
    setModalContra(!modalContra);
  };

  const [modalFoto, setModalFoto] = useState(false);
  const abrirCerrarModalFoto = () => {
    setModalFoto(!modalFoto);
  };
  ////FECHA
  const [modalFecha, setModalFecha] = useState(false);
  const abrirCerrarModalFecha = () => {
    setModalFecha(!modalFecha);
  };

  // Constantes
  const iduser = sessionStorage.getItem("iduser");
  const API_URL = "https://bqef-bo.com/api/B";
  const API_URL_P = "https://bqef-bo.com/api/P";
  const API_URL_A = "https://bqef-bo.com/api/A";

  // Funciones de petición GET
  const peticionGet = async () => {
    try {
      const response = await axios.get(`${API_URL}/contrabec.php`, {
        params: {
          id: sessionStorage.getItem("iduser"),
        },
      });
      const { data } = response;
      setData(data);
    } catch (error) {
      console.error("Error al obtener datos del usuario:", error);
      mostrarMensajeError("Error al obtener datos del usuario");
    }
  };
  const peticionGetFecha = async () => {
    try {
      const response = await axios.get(`${API_URL_P}/fecIP.php`);
      const { data } = response;
      setFec(data);
    } catch (error) {
      console.error("Error al obtener fecha:", error);
    }
  };
  const getImagenes = async () => {
    try {
      const response = await axios.get(`${API_URL}/imgPB.php`, {
        params: {
          id: sessionStorage.getItem("iduser"),
        },
      });
      const { data } = response;
      setListaI(data);
    } catch (error) {
      console.error("Error al obtener imágenes:", error);
    }
  };
  const getComunicado = async () => {
    try {
      const response = await axios.get(`${API_URL_A}/comCA.php`);
      const { data } = response;
      setLista(data);
    } catch (error) {
      console.error("Error al obtener comunicados:", error);
    }
  };
  // Funciones de petición PUT
  const peticionPutContra = async () => {
    try {
      var f = new FormData();
      f.append("contrasena", data.contrasena);
      f.append("contrasena_lit", data.contrasena_lit);
      f.append("usu_modificacion", data.id_bec);
      f.append("METHOD", "PUT");
      const response = await axios.post(`${API_URL}/contrabec.php`, f, {
        params: { idb: data.id },
      });
      setData(response);
      mostrarAlertaPA();
      abrirCerrarModalContra();
      peticionGet(); // Llama a la función peticionGet después de ejecutarse
      await peticionGet(); // Espera a que se resuelva la promesa de peticionGet
    } catch (error) {
      console.error("Error al actualizar contraseña:", error);
    }
  };

  const peticionPuti = async () => {
    try {
      var f = new FormData();
      f.append("archivo_foto", imagenP);
      f.append("METHOD", "PUT");
      const response = await axios.post(`${API_URL}/fotB.php`, f, {
        params: { id: data.id },
      });
      setData(response);
      abrirCerrarModalFoto();
      mostrarAlertaF();
      getImagenes();
    } catch (error) {
      console.error("Error al actualizar imagen de perfil:", error);
    }
  };
  ////sesion cierre
  const { isAuthenticated, logoutSubmit } = useAuth();
  const [remainingTime, setRemainingTime] = useState(TIMEOUT_DURATION);

  // Configurar el temporizador de inactividad
  useIdleTimer(TIMEOUT_DURATION, logoutSubmit, setRemainingTime);

  // Iniciar el temporizador solo si el usuario está autenticado
  useEffect(() => {
    if (isAuthenticated) {
      setRemainingTime(TIMEOUT_DURATION); // Reiniciar el tiempo restante
    } else {
      setRemainingTime(TIMEOUT_DURATION); // Reiniciar el tiempo si el usuario no está autenticado
    }
  }, [isAuthenticated]);

  // Función para formatear el tiempo restante en minutos y segundos
  const formatTime = (time) => {
    const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((time % (1000 * 60)) / 1000);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };
  //
  const navigate = useNavigate();
  // LOGOUT
  const logoutSub = () => {
    // Mostrar alerta de confirmación
    Swal.fire({
      title: "¿Está seguro?",
      text: "¿Desea cerrar sesión?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, cerrar sesión!",
    }).then((result) => {
      if (result.isConfirmed) {
        sessionStorage.clear(); // Limpia la sesión
        logoutSubmit(); // Actualiza el estado de autenticación
        navigate("/"); // Redirige a la página de inicio
      }
    });
  };
  ////
  const toggle = () => setIsOpen(!isOpen);
  ////CARGAR INFORMES
  async function addImagen(e) {
    if (nom.trim() === "" || !imagen) {
      if (nom.trim() === "") {
        setErrorNom("Debe ingresar un nombre de documento");
      }
      if (!imagen) {
        setErrorImagen("Debe seleccionar un archivo");
      }
      return;
    }
    e.preventDefault();
    let fd = new FormData();
    fd.append("archivo_per", imagen);
    fd.append("id_bec", data.id_bec);
    fd.append("nom_usu", data.nombre + " " + data.apellido);
    fd.append("nom_doc", nom);
    fd.append("usu_creacion", data.id_bec);
    const res = await axios.post("https://bqef-bo.com/api/B/archIB.php", fd);
    console.log(res.data);
    abrirCerrarModalInsertar();
    mostrarAlertaC();
  }
  const abrirCerrarModalInsertar = () => {
    setModalInsertar(!modalInsertar);
  };

  ////Descargar PDF
  const handleDescargarPdf = async () => {
    var link = document.createElement("a");
    link.setAttribute(
      "href",
      "data:application/pdf;base64," + selectedItem.archivo_com
    );
    link.setAttribute("download", selectedItem.nom_doc);
    link.click();
  };
  // Estado y efectos
  const [data, setData] = useState({});
  const [fec, setFec] = useState([]);
  const [listaI, setListaI] = useState([]);
  const [lista, setLista] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [imagenP, setImagenP] = useState(null);
  const [nom, setNom] = useState("");
  const [imagen, setImagen] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [modalInsertar, setModalInsertar] = useState(false);

  useEffect(() => {
    peticionGet();
    getImagenes();
    peticionGetFecha();
  }, []);

  useEffect(() => {
    getComunicado();
  }, []);
  ////ALERTAS
  const mostrarMensajeError = (mensaje) => {
    Swal.fire({
      icon: "error",
      title: "Error",
      text: mensaje,
      timer: 5000, // cerrar automáticamente el mensaje de error después de 5 segundos
    });
  };
  const mostrarAlertaC = () => {
    Swal.fire({
      confirmButtonColor: "#2E8B57",
      title: "Éxito!",
      text: "Documento Cargado.",
      timer: 5000,
      icon: "success",
    });
  };
  const mostrarAlertaF = () => {
    Swal.fire({
      confirmButtonColor: "#2E8B57",
      title: "Éxito!",
      text: "Foto Actualizada",
      timer: 5000,
      icon: "success",
    });
  };
  const mostrarAlertaPA = () => {
    Swal.fire({
      confirmButtonColor: "#2E8B57",
      title: "Éxito!",
      text: "Constraseña Actualizada",
      timer: 5000,
      icon: "success",
    });
  };
  ////
  const handleSelectItem = (item) => {
    setSelectedItem(item);
  };
  ////Boton Cerrar
  const closeBtn = () => {
    setSelectedItem(null);
  };
  const cloBtn = (
    <Button className="close" onClick={closeBtn}>
      &times;
    </Button>
  );
  ////
  const [errorNom, setErrorNom] = useState(null);
  const [errorImagen, setErrorImagen] = useState(null);
  const validarNombreDocumento = (e) => {
    const valor = e.target.value;
    const regex = /^[a-zA-Z\s]+$/;
    if (!regex.test(valor)) {
      setErrorNom(
        "El nombre del documento solo puede contener letras y espacios"
      );
    } else {
      setErrorNom(null);
    }
    setNom(e.target.value);
  };
  const isEnabled = nom.trim() !== "" && imagen !== null;

  /////
  const [alertVisible, setAlertVisible] = useState(true);
  const onDismiss = () => setAlertVisible(false);
  const abrirCerrarModalProyec = () => {
    // Aquí va el código para abrir el modalProy
    setModalFecha(!modalFecha);
  };
  ////Modal VER MANUAL USUARIO
  const [modalVerManual, setModalVerManual] = useState(false);
  const abrirCerrarModalVerManual = () => {
    setModalVerManual(!modalVerManual);
  };
  const toggleM = () => setModalVerManual(!modalVerManual);
  const closeBtn4 = (
    <Button className="close" onClick={() => abrirCerrarModalVerManual()}>
      &times;
    </Button>
  );
  ///pdf ver manual
  const handleDownloadPdf = () => {
    const link = document.createElement("a");
    link.href = require("../../Pages/Admin/documentos/manuales/MANUAL USUARIO BECARIO.pdf");
    link.download = "MANUAL USUARIO BECARIO.pdf";
    link.click();
  };
  // Definir un objeto de validación para contraseña
  const validation = {
    contrasena: {
      regex:
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%?&%"*#¡¿])[A-Za-z\d@$!%?&%"*#¡¿]{8,}$/,
      isValid: true,
    },
  };

  const [isValid, setIsValid] = useState({
    contrasena: false,
  });
  ///
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevState) => ({ ...prevState, [name]: value }));

    const isValidValue = validation[name].regex.test(value);
    setIsValid((prevState) => ({ ...prevState, [name]: isValidValue }));
  };
  return (
    <div>
      <Navbar expand="md" {...args}>
        <SidebarBec />
        <NavbarBrand>
          <Link to="/Becario/Becario" className="text-light">
            <img
              src={Logo}
              alt="logo"
              className="me-2"
              style={{
                height: 60,
                width: 120,
              }}
            />
          </Link>
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ms-auto" navbar>
            <NavbarText className="text-light">
              {/* Mostrar el temporizador de inactividad solo si el usuario está autenticado */}
              {isAuthenticated && (
                <div>Cierre de sesión en: {formatTime(remainingTime)}</div>
              )}
            </NavbarText>
            <>&nbsp;&nbsp;&nbsp;</>
            <NavItem>
              <Button
                style={{
                  marginTop: "5px",
                }}
                color="warning"
                onClick={() => abrirCerrarModalInsertar()}>
                <FaIcons.FaPlus /> ENVIAR INF
              </Button>
            </NavItem>
            <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
            <NavItem>
              {listaI.map((item) => (
                <div key={item.id}>
                  <CardImg
                    className="rounded-circle"
                    alt="Card image cap"
                    src={" data:image/png;base64," + item.foto}
                    style={{
                      height: 50,
                      width: 50,
                      marginTop: "2px",
                      border: "5px solid rgba(10, 49, 67, 1)",
                    }}
                    width="100%"
                    onClick={() => abrirCerrarModalFoto()}
                  />
                </div>
              ))}
            </NavItem>
            <>&nbsp;&nbsp;&nbsp;</>
            {/* //REVISAR */}
            <NavbarText className="text-light">{iduser}</NavbarText>
            <>&nbsp;&nbsp;&nbsp;</>
            <UncontrolledDropdown nav direction="down">
              <DropdownToggle nav caret className="text-light">
                MENU
              </DropdownToggle>
              <DropdownMenu className="drop-menu">
                <DropdownItem onClick={() => abrirCerrarModalVerManual()}>
                  Instrucciones
                </DropdownItem>
                {lista.map((item) => (
                  <DropdownItem
                    key={item.id_com}
                    onClick={() => handleSelectItem(item)}>
                    Postulacion
                  </DropdownItem>
                ))}
                <DropdownItem href="http://www.bqef.org/" target="_blank">
                  Sobre Nosotros...
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={() => abrirCerrarModalFoto()}>
                  Foto
                </DropdownItem>
                <DropdownItem onClick={() => abrirCerrarModalContra()}>
                  Cambiar Contraseña
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={logoutSub}>Salir</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </Navbar>
      <Alert
        color="danger"
        isOpen={alertVisible}
        toggle={onDismiss}
        style={{
          maxWidth: "80vw", // utiliza unidad relativa vw para ancho
          position: "absolute",
          top: "50%",
          right: "0%",
          transform: "translate(-50%, -50%)",
          animation: "slideIn 1s forwards",
          zIndex: 1000, // Agregamos z-index para que el Alert esté al frente
        }}>
        <span
          style={{
            cursor: "pointer",
            display: "flex", // Agregamos display: flex para alinear elementos
            alignItems: "center", // Centramos verticalmente los elementos
          }}
          onClick={() => abrirCerrarModalProyec()}>
          <FaIcons.FaExclamationCircle />
          &nbsp; F. Entrega &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </Alert>
      {/* MODAL INFORME */}
      <Modal isOpen={modalInsertar}>
        <ModalHeader className="header_mo">Cargar documento</ModalHeader>
        <ModalBody>
          <div className="form-group">
            <label>Nombre documento: </label>
            <br />
            <input
              type="text"
              className="form-control"
              name="nom_doc"
              onChange={(e) => validarNombreDocumento(e)}
            />
            {errorNom && <span style={{ color: "red" }}>{errorNom}</span>}
            <br />
            <label>Usuario: </label>
            <br />
            <input
              disabled
              type="text"
              className="form-control"
              name="nom_usu"
              value={data.nombre + " " + data.apellido}
            />
            <br />
            <label>ID Usuario: </label>
            <br />
            <input
              disabled
              type="text"
              className="form-control"
              name="id_bec"
              value={data.id_bec}
            />
            <br />
            <label>Informe personal: </label>
            <br />
            <input
              type="file"
              className="form-control"
              accept="archivo_per/*"
              onChange={(e) => setImagen(e.target.files[0])}
              multiple
            />
            <br />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="success"
            size="lg"
            onClick={(e) => addImagen(e)}
            disabled={!isEnabled}>
            Cargar
          </Button>
          <Button
            color="danger"
            size="lg"
            onClick={() => abrirCerrarModalInsertar()}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>

      {/* MODAL CONTRASEÑA */}
      <Modal isOpen={modalContra}>
        <ModalHeader className="header_mo">Editar Contraseña</ModalHeader>
        <ModalBody>
          <div className="form-group">
            <Label>Clave Actual: </Label>
            <br />
            <Input
              disabled
              type="text"
              className="form-control"
              name="contrasena_lit"
              onChange={handleChange}
              value={data.contrasena_lit}
            />
            <br />
            <Label>Nueva Clave: </Label>
            <br />
            <Input
              type="text"
              className="form-control"
              name="contrasena"
              onChange={handleChange}
              // value={data.contrasena}
            />
            {!isValid.contrasena && (
              <span style={{ color: "red" }}>
                La contraseña debe tener al menos 8 caracteres, incluyendo
                mayúsculas, minúsculas, números y caracteres especiales
              </span>
            )}
            <br />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="success"
            size="lg"
            onClick={() => peticionPutContra()}
            disabled={!isValid.contrasena}>
            Guardar
          </Button>
          {"   "}
          <Button
            color="danger"
            size="lg"
            onClick={() => abrirCerrarModalContra()}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>

      {/* MODAL FOTO */}
      <Modal isOpen={modalFoto}>
        <ModalHeader className="header_mo">Modificar Foto Perfil</ModalHeader>
        <ModalBody>
          <div className="form-group">
            <label>Foto Actual: </label>
            <br />
            {listaI.map((item) => (
              <div key={item.id}>
                <CardImg
                  className="rounded-circle"
                  alt="Card image cap"
                  src={" data:image/png;base64," + item.foto}
                  style={{
                    height: 200,
                    width: 200,
                    marginTop: "2px",
                    border: "5px solid rgba(10, 49, 67, 1)",
                    objectFit: "cover", // Ajusta la imagen al tamaño del contenedor
                    display: "block", // Comportamiento de bloque para centrar la imagen
                    marginLeft: "auto", // Centra la imagen horizontalmente
                    marginRight: "auto", // Centra la imagen horizontalmente
                  }}
                  width="100%"
                  onClick={() => abrirCerrarModalFoto()}
                />
              </div>
            ))}
            <br />
            <label>Nueva Foto: </label>
            <br />
            <Input
              type="file"
              className="form-control"
              accept="archivo_foto/*"
              onChange={(e) => setImagenP(e.target.files[0])}
              multiple
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn btn-success"
            size="lg"
            onClick={() => peticionPuti()}>
            Modificar
          </Button>
          <Button
            color="danger"
            size="lg"
            onClick={() => abrirCerrarModalFoto()}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
      {/* MODAL FECHA */}
      <Modal isOpen={modalFecha}>
        <ModalHeader className="header_mo">Entrega Informes</ModalHeader>
        <ModalBody>
          <div className="form-group">
            <Label>Fecha Establecida: </Label>
            {fec.map((item) => (
              <h2 className="text-center" key={item.id_fech}>
                "{item.fech_lit}"
              </h2>
            ))}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            size="lg"
            onClick={() => abrirCerrarModalFecha()}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>

      {/* Modal VER MANUAL USUARIO*/}
      <Modal isOpen={modalVerManual} size="xl">
        <ModalHeader className="header_mo" toggle={toggleM} close={closeBtn4}>
          Manual Usuario Becario
        </ModalHeader>
        <ModalBody>
          <Card>
            <CardBody className="p-0">
              <Row className="justify-content-center">
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    height: "500px",
                  }}>
                  <object
                    data={require("../../Pages/Admin/documentos/manuales/MANUAL USUARIO BECARIO.pdf")}
                    type="application/pdf"
                    width="100%"
                    height="100%">
                    <p>
                      Este navegador no soporta PDFs. Por favor,
                      <a
                        href={require("../../Pages/Admin/documentos/manuales/MANUAL USUARIO BECARIO.pdf")}>
                        {" "}
                        descarga el PDF
                      </a>{" "}
                      para verlo.
                    </p>
                  </object>
                </div>
              </Row>
            </CardBody>
          </Card>
        </ModalBody>
        <ModalFooter>
          <Button
            style={{ float: "right" }}
            color="success"
            size="lg"
            onClick={handleDownloadPdf}>
            <FaIcons.FaDownload />
            Download
          </Button>
        </ModalFooter>
      </Modal>
      {/* Modal VER CONVOCATORIA */}
      <Modal isOpen={selectedItem !== null} size="xl">
        {selectedItem && (
          <>
            <ModalHeader className="header_m" close={cloBtn}>
              {selectedItem.nom_doc}
            </ModalHeader>
            <ModalBody>
              <Card>
                <CardBody className="p-0">
                  <Row className="justify-content-center">
                    <object
                      data={
                        "data:application/pdf;base64," +
                        selectedItem.archivo_com
                      }
                      type="application/pdf"
                      alt="archivo_per"
                      width="400"
                      height="600">
                      <p>
                        Tu navegador no puede mostrar este archivo PDF. Puedes
                        descargarlo
                        <a
                          href={
                            "data:application/pdf;base64," +
                            selectedItem.archivo_com
                          }
                          download>
                          aquí
                        </a>
                        .
                      </p>
                    </object>
                  </Row>
                </CardBody>
              </Card>
            </ModalBody>
            <ModalFooter>
              <Button
                style={{ float: "right" }}
                color="success"
                size="lg"
                onClick={handleDescargarPdf}>
                <FaIcons.FaDownload />
                Download
              </Button>
            </ModalFooter>
          </>
        )}
      </Modal>
      <Outlet />
    </div>
  );
}

export default NavbarBec;
