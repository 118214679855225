import React, { useState, useEffect, useRef } from "react";
import { Input } from "reactstrap";
import { Table, Button } from "reactstrap";
import * as FaIcons from "react-icons/fa";
import axios from "axios";
import "../Bec/bec.scss";
import jsPDF from "jspdf";
import logos from "../../components/img/base/111.png";

function ListBe() {
  const baseUrl = `https://bqef-bo.com/api/P/cruB.php`;
  /////Listado usuarios becarios
  const [dataB, setDataB] = useState([]);

  useEffect(() => {
    const peticionGet = async () => {
      await axios
        .get(baseUrl)
        .then((response) => {
          //console.log(response.data);;
          setDataB(response.data);
          setTablaUsuarios(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    peticionGet();
  }, []);

  ////BARRA BUSQUEDA
  const [busqueda, setBusqueda] = useState("");
  const [tablaUsuarios, setTablaUsuarios] = useState([]);

  const handleChangeB = (e) => {
    setBusqueda(e.target.value);
    filtrar(e.target.value);
  };

  const filtrar = (terminoBusqueda) => {
    const regex = new RegExp(terminoBusqueda, "i");
    var resultadosBusqueda = tablaUsuarios.filter((elemento) => {
      return (
        regex.test(elemento.nombre) ||
        regex.test(elemento.apellido) ||
        regex.test(elemento.email) ||
        regex.test(elemento.institucion) ||
        regex.test(elemento.carrera) ||
        regex.test(elemento.anio) ||
        regex.test(elemento.celular) ||
        regex.test(elemento.ciudad) ||
        regex.test(elemento.direccion)
      );
    });
    setDataB(resultadosBusqueda);
  };
  //////Filtrar columnas
  const [sortField, setSortField] = useState(null);
  const [order, setOrder] = useState("asc");

  const handleSortingChange = (accessor) => {
    const sortOrder =
      accessor === sortField && order === "asc" ? "desc" : "asc";
    setSortField(accessor);
    setOrder(sortOrder);
  };

  const sortedData = dataB.sort((a, b) => {
    if (a[sortField] < b[sortField]) {
      return order === "asc" ? -1 : 1;
    }
    if (a[sortField] > b[sortField]) {
      return order === "asc" ? 1 : -1;
    }
    return 0;
  });
  ///GENERAR PDF INFORME
  const generatePDF = () => {
    // Crear un nuevo documento PDF en orientación horizontal (landscape)
    const doc = new jsPDF("landscape");

    // Agregar logo al documento PDF
    doc.addImage(logos, "PNG", 10, 10, 20, 10);

    // Establecer la fuente en negrita
    doc.setFont("helvetica", "bold");

    // Agregar título "FONDO DE EDUCACIÓN" al documento PDF
    doc.setFontSize(12);
    const title = "FONDO DE EDUCACION DE CUAQUEROS BOLIVIANOS";
    const titleWidth = doc.getTextWidth(title);
    const x = (doc.internal.pageSize.width - titleWidth) / 2; // Calcular posición X para centrar
    const y = 20; // Posición Y del título

    // Escribir el título
    doc.text(title, x, y);

    // Dibujar la línea subrayando el título
    doc.line(x, y + 1, x + titleWidth, y + 1); // Dibuja la línea

    // Restablecer la fuente a normal
    doc.setFont("helvetica", "normal");

    // Agregar subtítulo "Reporte" al documento PDF
    const subtitle = "Reporte Datos Becarios";
    const subtitleWidth = doc.getTextWidth(subtitle);
    const subtitleX = (doc.internal.pageSize.width - subtitleWidth) / 2; // Calcular posición X para centrar
    doc.text(subtitle, subtitleX, 30);

    // Agregar fecha al documento PDF
    const fecha = new Date();
    const dia = fecha.getDate();
    const mes = fecha.getMonth() + 1;
    const año = fecha.getFullYear();
    doc.setFontSize(9);
    doc.text(`Fecha: ${dia}/${mes}/${año}`, 10, 25);

    // Establecer la fuente en negrita
    doc.setFont("helvetica", "bold");

    // Definir el texto "Usuario: "
    const usuarioText = "Usuario: Personal Administrativo";

    // Calcular el ancho del texto
    const usuarioWidth = doc.getTextWidth(usuarioText);

    // Calcular la posición X para alinearlo a la derecha
    const usuarioX = doc.internal.pageSize.width - usuarioWidth - 10; // 10 mm de margen desde el borde derecho

    // Escribir el texto "Usuario:" en la posición calculada
    doc.text(usuarioText, usuarioX, 35);

    // Definir los datos de la tabla
    const userData = dataB
      .map((iteb) => ({
        nom_b: `${iteb.nombre} ${iteb.apellido}`,
        email: iteb.email,
        institucion: iteb.institucion,
        carrera: iteb.carrera,
        anio: iteb.anio,
        celular: iteb.celular,
        ciudad: iteb.ciudad,
        direccion: iteb.direccion,
        nom_pad: iteb.nom_pad,
        nom_mad: iteb.nom_mad,
      }))
      .sort((a, b) => a.nom_b.localeCompare(b.nom_b)) // Ordenar por nombre en orden alfabético ascendente
      .map((iteb, nb) => ({
        it: nb + 1, // Asignar el índice de forma ascendente después de ordenar
        ...iteb, // Desestructurar el resto de las propiedades
      }));

    // Definir las columnas de la tabla
    const columns = [
      { header: "N°", dataKey: "it" },
      { header: "Nombre Becario", dataKey: "nom_b" },
      { header: "Correo", dataKey: "email" },
      { header: "Institución", dataKey: "institucion" },
      { header: "Carrera", dataKey: "carrera" },
      { header: "Año", dataKey: "anio" },
      { header: "Telf/Cel", dataKey: "celular" },
      { header: "Ciudad", dataKey: "ciudad" },
      { header: "Dirección", dataKey: "direccion" },
      { header: "Nombre Padre", dataKey: "nom_pad" },
      { header: "Nombre Madre", dataKey: "nom_mad" },
    ];

    // Restablecer la fuente a normal
    doc.setFont("helvetica", "normal");

    // Agregar la tabla al documento PDF
    doc.autoTable(columns, userData, {
      startY: 40,
      margin: { top: 20, bottom: 20 },
      bodyStyles: { fontSize: 8 },
      headStyles: { fontSize: 9, fillColor: [10, 49, 67] },
    });

    // Agregar número de página al documento PDF
    let totalPages = doc.internal.getNumberOfPages();
    for (let i = 1; i <= totalPages; i++) {
      doc.setPage(i);
      doc.setFontSize(9);
      doc.text(
        `Página ${i} de ${totalPages}`,
        10,
        doc.internal.pageSize.height - 10
      );
    }

    // Convertir el documento PDF a blob
    const pdfBlob = doc.output("blob");

    // Abrir el PDF en una nueva ventana
    const url = URL.createObjectURL(pdfBlob);
    window.open(url, "_blank");
  };
  return (
    <div id="main_content">
      <div className="tra">
        <div className="tra title-form">
          <h2 className>Listado Becarios</h2>
        </div>
        <div className="frm">
          <Button color="primary" size="lg" onClick={generatePDF}>
            <FaIcons.FaFileDownload /> Reporte
          </Button>
        </div>
        <div className="frm containerInput">
          <Input
            className="form-control inputBuscar"
            size="lg"
            value={busqueda}
            placeholder="Búsqueda por Nombre, Apellido o Email"
            onChange={handleChangeB}
          />
          <Button className="btn btn-success" size="lg">
            <FaIcons.FaSearch /> Buscar
          </Button>
          <br />
        </div>

        <Table className="frm" responsive="sm" id="tabl">
          <thead id="stahead">
            <tr className="text-center">
              <th>N°</th>
              <th>
                <span onClick={() => handleSortingChange("nombre")}>
                  Nombres
                  {sortField === "nombre" && order === "asc" ? (
                    <FaIcons.FaSortUp />
                  ) : sortField === "nombre" && order === "desc" ? (
                    <FaIcons.FaSortDown />
                  ) : (
                    <FaIcons.FaSort />
                  )}
                </span>
              </th>
              <th>
                <span onClick={() => handleSortingChange("apellido")}>
                  Apellidos
                  {sortField === "apellido" && order === "asc" ? (
                    <FaIcons.FaSortUp />
                  ) : sortField === "apellido" && order === "desc" ? (
                    <FaIcons.FaSortDown />
                  ) : (
                    <FaIcons.FaSort />
                  )}
                </span>
              </th>
              <th>
                <span onClick={() => handleSortingChange("email")}>
                  Correo
                  {sortField === "email" && order === "asc" ? (
                    <FaIcons.FaSortUp />
                  ) : sortField === "email" && order === "desc" ? (
                    <FaIcons.FaSortDown />
                  ) : (
                    <FaIcons.FaSort />
                  )}
                </span>
              </th>
              <th>
                <span onClick={() => handleSortingChange("universidad")}>
                  Universidad
                  {sortField === "universidad" && order === "asc" ? (
                    <FaIcons.FaSortUp />
                  ) : sortField === "universidad" && order === "desc" ? (
                    <FaIcons.FaSortDown />
                  ) : (
                    <FaIcons.FaSort />
                  )}
                </span>
              </th>
              <th>
                <span onClick={() => handleSortingChange("carrera")}>
                  Carrera
                  {sortField === "carrera" && order === "asc" ? (
                    <FaIcons.FaSortUp />
                  ) : sortField === "carrera" && order === "desc" ? (
                    <FaIcons.FaSortDown />
                  ) : (
                    <FaIcons.FaSort />
                  )}
                </span>
              </th>
              <th>
                <span onClick={() => handleSortingChange("anio")}>
                  Año
                  {sortField === "anio" && order === "asc" ? (
                    <FaIcons.FaSortUp />
                  ) : sortField === "anio" && order === "desc" ? (
                    <FaIcons.FaSortDown />
                  ) : (
                    <FaIcons.FaSort />
                  )}
                </span>
              </th>
              <th>
                <span onClick={() => handleSortingChange("celular")}>
                  Telf/Cel
                  {sortField === "celular" && order === "asc" ? (
                    <FaIcons.FaSortUp />
                  ) : sortField === "celular" && order === "desc" ? (
                    <FaIcons.FaSortDown />
                  ) : (
                    <FaIcons.FaSort />
                  )}
                </span>
              </th>
              <th>
                <span onClick={() => handleSortingChange("ciudad")}>
                  Ciudad
                  {sortField === "ciudad" && order === "asc" ? (
                    <FaIcons.FaSortUp />
                  ) : sortField === "ciudad" && order === "desc" ? (
                    <FaIcons.FaSortDown />
                  ) : (
                    <FaIcons.FaSort />
                  )}
                </span>
              </th>
              <th>
                <span onClick={() => handleSortingChange("direccion")}>
                  Dirección
                  {sortField === "direccion" && order === "asc" ? (
                    <FaIcons.FaSortUp />
                  ) : sortField === "direccion" && order === "desc" ? (
                    <FaIcons.FaSortDown />
                  ) : (
                    <FaIcons.FaSort />
                  )}
                </span>
              </th>
              <th>
                <span onClick={() => handleSortingChange("nom_pad")}>
                  Nombre Padre
                  {sortField === "nom_pad" && order === "asc" ? (
                    <FaIcons.FaSortUp />
                  ) : sortField === "nom_pad" && order === "desc" ? (
                    <FaIcons.FaSortDown />
                  ) : (
                    <FaIcons.FaSort />
                  )}
                </span>
              </th>
              <th>
                <span onClick={() => handleSortingChange("nom_mad")}>
                  Nombre Madre
                  {sortField === "nom_mad" && order === "asc" ? (
                    <FaIcons.FaSortUp />
                  ) : sortField === "nom_mad" && order === "desc" ? (
                    <FaIcons.FaSortDown />
                  ) : (
                    <FaIcons.FaSort />
                  )}
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedData.map((item, nu3) => (
              <tr className="text-center" key={item.id}>
                <td>{nu3 + 1}</td>
                <td>{item.nombre}</td>
                <td>{item.apellido}</td>
                <td>{item.email}</td>
                <td>{item.institucion}</td>
                <td>{item.carrera}</td>
                <td>{item.anio}</td>
                <td>{item.celular}</td>
                <td>{item.ciudad}</td>
                <td>{item.direccion}</td>
                <td>{item.nom_pad}</td>
                <td>{item.nom_mad}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default ListBe;
